import React, { useEffect, useState } from 'react';
import { API, Storage } from 'aws-amplify';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import { useHistory, useParams } from 'react-router-dom';

import { GetFreight, UpdateFreight } from './graphql';
import { capitalizeFirstLetter } from 'utils';
import { FREIGHTS_STATUS } from '../Travel/constants';
import { PLAN_TITLE } from '../Cost/constants';
import { Wrapper, Status } from '../FreightDriverTruck/styles';
import { Icon } from 'components/Icon';

export interface ITravel {
  id: string;
  time: string;
  type?: 'ENTERPRISE';
  paymentMethod: 'stripe' | 'cash' | 'transfer';
  travelDistance: number;
  travelTime: string;
  travelExpenses: number;
  tollPrice: number;
  fuelPrice: number;
  merchandiseDescription: string;
  packingDescription?: string;
  hasManeuvers: boolean;
  maneuversPrice: number;
  hasInsurance: boolean;
  merchandiseValue: number;
  insurancePrice: number;
  insuranceDiscount: number;
  additionalComments?: string;
  status: string;
  plan: 'basic' | 'normal' | 'shared' | 'prime';
  utilityPercentage: number;
  discount: number;
  discountPrice: number;
  freightDriverCommission: number;
  companyCommission: number;
  freightDriverSalary: number;
  pricePerBoxLength: number;
  taxesPrice: number;
  subTotalPrice: number;
  totalPrice: number;
  user: {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
  };
  truck: {
    boxType: string;
    boxWidth: number;
    boxHeight: number;
    boxLength: number;
    axles: number;
    fuelType: string;
    fuelEfficiency: number;
    tons: number;
  };
  documentation?: {
    type: string;
    document: string;
  }[];
  freightDriver?: {
    id: string;
    companyName: string;
  };
  origin: {
    city: string;
    state: string;
    latitude: number;
    longitude: number;
    address: string;
    street?: string;
    zipCode?: string;
    suburb?: string;
    personCharge?: {
      name: string;
      phoneNumber: number;
    };
  };
  destination: {
    city: string;
    state: string;
    latitude: number;
    longitude: number;
    address: string;
    street?: string;
    zipCode?: string;
    suburb?: string;
    personCharge?: {
      name: string;
      phoneNumber: number;
    };
  };
  gps?: {
    name: string;
    url: string;
    username: string;
    password: string;
  };
  createdAt: string;
  updatedAt: string;
}

const PaymentMethod = {
  stripe: 'Tarjeta de debito o crédito',
  cash: 'Paga en efectivo',
  transfer: 'Transferencia bancaria',
};

export const TravelDetail = () => {
  const router = useHistory();
  const { id } = useParams<{
    id: string;
  }>();
  const [status, setStatus] = useState('idle');
  const [travel, setTravel] = useState<ITravel>({
    id: '',
    time: '',
    paymentMethod: 'stripe',
    travelDistance: 0,
    travelTime: '',
    travelExpenses: 0,
    tollPrice: 0,
    fuelPrice: 0,
    merchandiseDescription: '',
    packingDescription: '',
    hasManeuvers: false,
    maneuversPrice: 0,
    hasInsurance: false,
    merchandiseValue: 0,
    insurancePrice: 0,
    insuranceDiscount: 0,
    additionalComments: '',
    status: '',
    plan: 'basic',
    utilityPercentage: 0,
    discount: 0,
    discountPrice: 0,
    freightDriverCommission: 0,
    companyCommission: 0,
    freightDriverSalary: 0,
    pricePerBoxLength: 0,
    taxesPrice: 0,
    subTotalPrice: 0,
    totalPrice: 0,
    user: {
      id: '',
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
    },
    truck: {
      boxType: '',
      boxWidth: 0,
      boxHeight: 0,
      boxLength: 0,
      axles: 0,
      fuelType: '',
      fuelEfficiency: 0,
      tons: 0,
    },
    documentation: [],
    freightDriver: {
      id: '',
      companyName: '',
    },
    origin: {
      city: '',
      state: '',
      latitude: 0,
      longitude: 0,
      address: '',
      street: '',
      zipCode: '',
      suburb: '',
      personCharge: {
        name: '',
        phoneNumber: 0,
      },
    },
    destination: {
      city: '',
      state: '',
      latitude: 0,
      longitude: 0,
      address: '',
      street: '',
      zipCode: '',
      suburb: '',
      personCharge: {
        name: '',
        phoneNumber: 0,
      },
    },
    gps: {
      name: '',
      url: '',
      username: '',
      password: '',
    },
    createdAt: '',
    updatedAt: '',
  });
  const [isDotsOptionOpen, setIsDotsOptionOpen] = useState(false);
  const isLoading = status === 'idle' || status === 'pending';
  const currentTime = travel?.time || new Date();
  const currentDate = format(new Date(currentTime), 'dd MMMM yyyy', {
    locale: es,
  });
  const currentHour = format(new Date(currentTime), 'hh:mm aaaa', {
    locale: es,
  });

  useEffect(() => {
    async function getQuery() {
      setStatus('pending');

      try {
        const { data } = (await API.graphql({
          query: GetFreight,
          variables: {
            id,
          },
        })) as any;

        if (data) {
          let documentation = data.getFreight.documentation;

          if (documentation) {
            // If there's any s3 route in `document`, fetch this file
            documentation = await Promise.all(
              documentation.map(
                async (values: { type: string; document: string }) =>
                  values.document
                    ? {
                        type: values.type,
                        document: await Storage.get(values.document, {
                          contentType:
                            values.document.split('.').pop() === 'pdf'
                              ? 'application/pdf'
                              : 'image/jpg',
                        }),
                      }
                    : values
              )
            );
          }

          setTravel({ ...data.getFreight, documentation });
        }

        setStatus('resolved');
      } catch {
        setStatus('rejected');
      }
    }

    getQuery();
  }, [id]);

  useEffect(() => {
    const handleUpdateFreightUpdateDate = async () => {
      const currentDate = new Date().toJSON();

      await API.graphql({
        query: UpdateFreight,
        variables: {
          input: {
            id,
            updatedAt: currentDate,
          },
        },
      });
    };

    if (status === 'resolved' && travel.createdAt === travel.updatedAt) {
      handleUpdateFreightUpdateDate();
    }
  }, [travel.createdAt, travel.updatedAt, status]);

  const handleFreightStatus = async (status: string) => {
    const id = travel?.id;

    if (!id || !status) return;

    const updateFreight = (await API.graphql({
      query: UpdateFreight,
      variables: {
        input: {
          id,
          status,
        },
      },
    })) as any;

    if (updateFreight.data.updateFreight?.id) {
      window.location.reload();
    }
  };

  if (isLoading) {
    return <div />;
  }

  return (
    <Wrapper>
      <div className="section">
        <div className="first-card">
          <figure className="icon">
            <Icon iconType="localshipping" />
          </figure>
          <p className="text">
            {capitalizeFirstLetter(travel.origin.city)} -{' '}
            {capitalizeFirstLetter(travel.destination.city)}{' '}
            <span>&nbsp;·&nbsp;</span>
            {format(
              new Date(currentTime),
              travel.type === 'ENTERPRISE'
                ? 'dd MMMM yyyy'
                : 'dd MMMM yyyy · hh:mm aaaa',
              {
                locale: es,
              }
            )}
          </p>
          {travel.status && (
            <Status
              color={
                FREIGHTS_STATUS.find(option => option.status === travel.status)
                  ?.color
              }
            >
              <Icon
                iconType={
                  FREIGHTS_STATUS.find(
                    option => option.status === travel.status
                  )?.icon
                }
              />
              <span>
                {
                  FREIGHTS_STATUS.find(
                    option => option.status === travel.status
                  )?.text
                }
              </span>
            </Status>
          )}
          <div className="dots-content">
            <div
              className="dots"
              onClick={() => setIsDotsOptionOpen(!isDotsOptionOpen)}
            >
              <div />
              <div />
              <div />
            </div>
            {isDotsOptionOpen && (
              <div className="dots-buttons">
                <div
                  className="dots-button"
                  onClick={() => {
                    router.push(`/travel/${id}/edit`);
                  }}
                >
                  <span>Editar viaje</span>
                </div>
                <div
                  className="dots-button"
                  onClick={() => {
                    if (
                      window.confirm('¿Esta seguro de reservar este viaje?')
                    ) {
                      handleFreightStatus('PAYMENT_ACCEPTED');
                    }
                  }}
                >
                  <span>Reservar viaje</span>
                </div>
                <div
                  className="dots-button"
                  onClick={() => {
                    if (window.confirm('¿Esta seguro de iniciar este viaje?')) {
                      handleFreightStatus('SERVICE_IN_PROGRESS');
                    }
                  }}
                >
                  <span>Iniciar viaje</span>
                </div>
                <div
                  className="dots-button"
                  onClick={() => {
                    if (
                      window.confirm(
                        '¿Esta seguro de marcar como completado este viaje?'
                      )
                    ) {
                      handleFreightStatus('SERVICE_COMPLETED');
                    }
                  }}
                >
                  <span>Viaje completado</span>
                </div>
                <div
                  className="dots-button"
                  onClick={() => {
                    if (
                      window.confirm('¿Esta seguro de cancelar este viaje?')
                    ) {
                      handleFreightStatus('SERVICE_CANCELLED');
                    }
                  }}
                >
                  <span>Cancelar viaje</span>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="section">
          <h3 className="section-name">ID de viaje</h3>
          <div className="box">
            <ul className="box-items">
              <li className="item">
                <h4 className="item-name">ID</h4>
                <p className="item-value">{travel.id}</p>
              </li>
              <li className="item">
                <h4 className="item-name">Creación de flete</h4>
                <p className="item-value">
                  {format(
                    new Date(travel.createdAt),
                    'dd MMMM yyyy · hh:mm aaaa',
                    {
                      locale: es,
                    }
                  )}
                </p>
              </li>
            </ul>
          </div>
        </div>
        <div className="section">
          <h3 className="section-name">Usuario</h3>
          <div className="box">
            <ul className="box-items">
              <li className="item">
                <h4 className="item-name">Nombre</h4>
                <p className="item-value">{travel.user.firstName}</p>
              </li>
              <li className="item">
                <h4 className="item-name">Apellido</h4>
                <p className="item-value">{travel.user.lastName}</p>
              </li>
              <li className="item">
                <h4 className="item-name">Correo electrónico</h4>
                <p className="item-value">{travel.user.email}</p>
              </li>
              <li className="item">
                <h4 className="item-name">Teléfono</h4>
                <p className="item-value">{travel.user.phoneNumber}</p>
              </li>
            </ul>
          </div>
        </div>
        <div className="section">
          <h3 className="section-name">Origen</h3>
          <div className="box">
            <ul className="box-items">
              <li className="item">
                <h4 className="item-name">Ciudad</h4>
                <p className="item-value">{travel.origin.city}</p>
              </li>
              <li className="item">
                <h4 className="item-name">Estado</h4>
                <p className="item-value">{travel.origin.state}</p>
              </li>
              <li className="item">
                <h4 className="item-name">Latitud</h4>
                <p className="item-value">{travel.origin.latitude}</p>
              </li>
              <li className="item">
                <h4 className="item-name">Longitud</h4>
                <p className="item-value">{travel.origin.longitude}</p>
              </li>
              <li className="item">
                <h4 className="item-name">Dirección</h4>
                <p className="item-value">{travel.origin.address}</p>
              </li>
              <li className="item">
                <h4 className="item-name">Calle y número</h4>
                <p className="item-value">{travel.origin.street}</p>
              </li>
              <li className="item">
                <h4 className="item-name">Código postal</h4>
                <p className="item-value">{travel.origin.zipCode}</p>
              </li>
              <li className="item">
                <h4 className="item-name">Colonia</h4>
                <p className="item-value">{travel.origin.suburb}</p>
              </li>
              <li className="item">
                <h4 className="item-name">Nombre de persona a cargo</h4>
                <p className="item-value">{travel.origin.personCharge?.name}</p>
              </li>
              <li className="item">
                <h4 className="item-name">Teléfono de persona a cargo</h4>
                <p className="item-value">
                  {travel.origin.personCharge?.phoneNumber}
                </p>
              </li>
            </ul>
          </div>
        </div>
        <div className="section">
          <h3 className="section-name">Destino</h3>
          <div className="box">
            <ul className="box-items">
              <li className="item">
                <h4 className="item-name">Ciudad</h4>
                <p className="item-value">{travel.destination.city}</p>
              </li>
              <li className="item">
                <h4 className="item-name">Estado</h4>
                <p className="item-value">{travel.destination.state}</p>
              </li>
              <li className="item">
                <h4 className="item-name">Latitud</h4>
                <p className="item-value">{travel.destination.latitude}</p>
              </li>
              <li className="item">
                <h4 className="item-name">Longitud</h4>
                <p className="item-value">{travel.destination.longitude}</p>
              </li>
              <li className="item">
                <h4 className="item-name">Dirección</h4>
                <p className="item-value">{travel.destination.address}</p>
              </li>
              <li className="item">
                <h4 className="item-name">Calle y número</h4>
                <p className="item-value">{travel.destination.street}</p>
              </li>
              <li className="item">
                <h4 className="item-name">Código postal</h4>
                <p className="item-value">{travel.destination.zipCode}</p>
              </li>
              <li className="item">
                <h4 className="item-name">Colonia</h4>
                <p className="item-value">{travel.destination.suburb}</p>
              </li>
              <li className="item">
                <h4 className="item-name">Nombre de persona a cargo</h4>
                <p className="item-value">
                  {travel.destination.personCharge?.name}
                </p>
              </li>
              <li className="item">
                <h4 className="item-name">Teléfono de persona a cargo</h4>
                <p className="item-value">
                  {travel.destination.personCharge?.phoneNumber}
                </p>
              </li>
            </ul>
          </div>
        </div>
        {travel.freightDriver?.id && (
          <div className="section">
            <h3 className="section-name">Empresa transportista</h3>
            <div className="box">
              <ul className="box-items">
                <li className="item">
                  <h4 className="item-name">Nombre de compañia</h4>
                  <a
                    className="item-value"
                    rel="noopener noreferrer"
                    target="_blank"
                    href={`/freights/${travel.freightDriver?.id}`}
                  >
                    {travel.freightDriver?.companyName}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        )}
        {!travel.type ? (
          <>
            <div className="section">
              <h3 className="section-name">GPS</h3>
              <div className="box">
                <ul className="box-items">
                  <li className="item">
                    <h4 className="item-name">Nombre</h4>
                    <p className="item-value">{travel.gps?.name}</p>
                  </li>
                  <li className="item">
                    <h4 className="item-name">URL</h4>
                    <p className="item-value">{travel.gps?.url}</p>
                  </li>
                  <li className="item">
                    <h4 className="item-name">Nombre de usuario</h4>
                    <p className="item-value">{travel.gps?.username}</p>
                  </li>
                  <li className="item">
                    <h4 className="item-name">Contraseña</h4>
                    <p className="item-value">{travel.gps?.password}</p>
                  </li>
                </ul>
              </div>
            </div>
            <div className="section">
              <h3 className="section-name">Información adicional</h3>
              <div className="box">
                <ul className="box-items">
                  <li className="item">
                    <h4 className="item-name">Fecha</h4>
                    <p className="item-value">
                      Hecho el {currentDate} a las {currentHour}
                    </p>
                  </li>
                  <li className="item">
                    <h4 className="item-name">Distancia de viaje en K.M</h4>
                    <p className="item-value">{travel.travelDistance}</p>
                  </li>
                  <li className="item">
                    <h4 className="item-name">Tiempo de viaje en horas</h4>
                    <p className="item-value">{travel.travelTime}</p>
                  </li>
                  <li className="item">
                    <h4 className="item-name">Gastos de viaje</h4>
                    <p className="item-value">
                      ${travel.travelExpenses.toLocaleString()}
                    </p>
                  </li>
                  <li className="item">
                    <h4 className="item-name">Costo de peaje</h4>
                    <p className="item-value">
                      ${travel.tollPrice.toLocaleString()}
                    </p>
                  </li>
                  <li className="item">
                    <h4 className="item-name">Costo de combustible</h4>
                    <p className="item-value">
                      ${travel.fuelPrice.toLocaleString()}
                    </p>
                  </li>
                  <li className="item">
                    <h4 className="item-name">Descripción de mercancia</h4>
                    <p className="item-value">
                      {travel.merchandiseDescription}
                    </p>
                  </li>
                  <li className="item">
                    <h4 className="item-name">Descripción de embalaje</h4>
                    <p className="item-value">{travel.packingDescription}</p>
                  </li>
                  <li className="item">
                    <h4 className="item-name">
                      ¿Cuenta con maniobras de carga?
                    </h4>
                    <p className="item-value">
                      {travel.hasManeuvers ? 'Sí' : 'No'}
                    </p>
                  </li>
                  {travel.hasManeuvers && (
                    <li className="item">
                      <h4 className="item-name">Precio maniobras de carga</h4>
                      <p className="item-value">
                        ${travel.maneuversPrice.toLocaleString()}
                      </p>
                    </li>
                  )}
                  <li className="item">
                    <h4 className="item-name">¿Cuenta con seguro?</h4>
                    <p className="item-value">
                      {travel.hasInsurance ? 'Sí' : 'No'}
                    </p>
                  </li>
                  {travel.hasInsurance && (
                    <>
                      <li className="item">
                        <h4 className="item-name">Costo de mercancía</h4>
                        <p className="item-value">
                          ${travel.merchandiseValue.toLocaleString()}
                        </p>
                      </li>
                      <li className="item">
                        <h4 className="item-name">Costo de seguro</h4>
                        <p className="item-value">
                          ${travel.insurancePrice.toLocaleString()}
                        </p>
                      </li>
                      <li className="item">
                        <h4 className="item-name">Descuento de seguro</h4>
                        <p className="item-value">{travel.insuranceDiscount}</p>
                      </li>
                    </>
                  )}
                  <li className="item">
                    <h4 className="item-name">Comentarios adicionales</h4>
                    <p className="item-value">{travel.additionalComments}</p>
                  </li>
                  <li className="item">
                    <h4 className="item-name">Plan</h4>
                    <p className="item-value">{PLAN_TITLE[travel.plan]}</p>
                  </li>
                  <li className="item">
                    <h4 className="item-name">Metodo de pago</h4>
                    <p className="item-value">
                      {PaymentMethod[travel.paymentMethod]}
                    </p>
                  </li>
                  <li className="item">
                    <h4 className="item-name">Porcentaje de utilidad</h4>
                    <p className="item-value">{travel.utilityPercentage}</p>
                  </li>
                  <li className="item">
                    <h4 className="item-name">Descuento</h4>
                    <p className="item-value">{travel.discount}</p>
                  </li>
                  <li className="item">
                    <h4 className="item-name">Descuento precio</h4>
                    <p className="item-value">{travel.discountPrice}</p>
                  </li>
                  <li className="item">
                    <h4 className="item-name">Comisión de transportista</h4>
                    <p className="item-value">
                      ${travel.freightDriverCommission.toLocaleString()}
                    </p>
                  </li>
                  <li className="item">
                    <h4 className="item-name">Comisión de compañia Yago</h4>
                    <p className="item-value">
                      ${travel.companyCommission.toLocaleString()}
                    </p>
                  </li>
                  <li className="item">
                    <h4 className="item-name">Salario de transportista</h4>
                    <p className="item-value">
                      ${travel.freightDriverSalary.toLocaleString()}
                    </p>
                  </li>
                  <li className="item">
                    <h4 className="item-name">Costo por caja</h4>
                    <p className="item-value">
                      ${travel.pricePerBoxLength.toLocaleString()}
                    </p>
                  </li>
                  <li className="item">
                    <h4 className="item-name">Costo por impuestos</h4>
                    <p className="item-value">
                      ${travel.taxesPrice.toLocaleString()}
                    </p>
                  </li>
                  <li className="item">
                    <h4 className="item-name">Total</h4>
                    <p className="item-value">
                      ${travel.totalPrice.toLocaleString()}
                    </p>
                  </li>
                  <li className="item">
                    <h4 className="item-name">Tipo de caja de camión</h4>
                    <p className="item-value">{travel.truck.boxType}</p>
                  </li>
                  <li className="item">
                    <h4 className="item-name">Ancho de caja de camión</h4>
                    <p className="item-value">{travel.truck.boxWidth}</p>
                  </li>
                  <li className="item">
                    <h4 className="item-name">Alto de caja de camión</h4>
                    <p className="item-value">{travel.truck.boxHeight}</p>
                  </li>
                  <li className="item">
                    <h4 className="item-name">Largo de caja de camión</h4>
                    <p className="item-value">{travel.truck.boxLength}</p>
                  </li>
                  <li className="item">
                    <h4 className="item-name">Ejes camión</h4>
                    <p className="item-value">{travel.truck.axles}</p>
                  </li>
                  <li className="item">
                    <h4 className="item-name">Tipo de gasolina de camión</h4>
                    <p className="item-value">{travel.truck.fuelType}</p>
                  </li>
                  <li className="item">
                    <h4 className="item-name">Eficiencia de camión</h4>
                    <p className="item-value">{travel.truck.fuelEfficiency}</p>
                  </li>
                  <li className="item">
                    <h4 className="item-name">Toneladas de camión</h4>
                    <p className="item-value">{travel.truck.tons}</p>
                  </li>
                </ul>
              </div>
            </div>
          </>
        ) : (
          <div className="section">
            <h3 className="section-name">ENTERPRISE</h3>
            <div className="box">
              <ul className="box-items">
                <li className="item">
                  <h4 className="item-name">Sub Total</h4>
                  <p className="item-value">
                    {travel.subTotalPrice
                      ? `$${travel.subTotalPrice.toLocaleString()}`
                      : ''}
                  </p>
                </li>
                <li className="item">
                  <h4 className="item-name">Total</h4>
                  <p className="item-value">
                    {travel.totalPrice
                      ? `$${travel.totalPrice.toLocaleString()}`
                      : ''}
                  </p>
                </li>
              </ul>
            </div>
          </div>
        )}
        {travel.documentation && (
          <div className="section">
            <h3 className="section-name">Documentación</h3>
            <div className="box">
              <ul className="box-items">
                {travel.documentation?.map(({ type, document }, index) => (
                  <li className="item" key={index}>
                    <h4 className="item-name">{type}</h4>
                    {document && (
                      <a
                        className="item-value"
                        rel="noopener noreferrer"
                        target="_blank"
                        href={document}
                      >
                        Descargar
                      </a>
                    )}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        )}
      </div>
    </Wrapper>
  );
};
