import React from 'react';

export const Facebook = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
  >
    <g
      id="Logo_FB"
      data-name="Logo FB"
      transform="translate(-45.221 200.779)"
    >
      <g id="Layer_1" data-name="Layer 1" transform="translate(45 -201)">
        <g id="Facebook_Icon_02" data-name="Facebook Icon 02">
          <circle
            id="Background"
            cx="11"
            cy="11"
            r="11"
            transform="translate(0.221 0.221)"
            fill="#fff"
          />
          <path
            id="F"
            /* eslint-disable-next-line max-len */
            d="M108.5,68.51h-1.72a3.021,3.021,0,0,0-3.181,3.264v1.505h-1.73a.27.27,0,0,0-.27.27v2.182a.27.27,0,0,0,.27.27h1.73v5.5a.27.27,0,0,0,.27.27h2.258a.27.27,0,0,0,.27-.27V76h2.021a.27.27,0,0,0,.271-.27V73.547a.272.272,0,0,0-.271-.271H106.4V72c0-.613.148-.925.944-.925H108.5a.271.271,0,0,0,.27-.27V68.78a.27.27,0,0,0-.27-.27Z"
            transform="translate(-94.075 -63.435)"
            fill="#1476ef"
          />
        </g>
      </g>
    </g>
  </svg>
);
