import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { API, Storage } from 'aws-amplify';

import { GetFreight, UpdateLocation } from './graphql';
import { UpdateFreight } from '../TravelDetail/graphql';
import { Input } from '../FreightDriverTruckEdit/input';
import { Wrapper } from '../FreightDriverTruck/styles';
import { removeDuplicates } from 'utils/removeDuplicates';

export interface ITravel {
  type?: 'ENTERPRISE';
  documentation?: {
    type: string;
    document: string | File;
  }[];
  origin: {
    id: string;
    city: string;
    state: string;
    latitude: number;
    longitude: number;
    address: string;
    street: string;
    zipCode: string;
    suburb: string;
    personChargeName: string;
    personChargePhoneNumber: number;
  };
  destination: {
    id: string;
    city: string;
    state: string;
    latitude: number;
    longitude: number;
    address: string;
    street: string;
    zipCode: string;
    suburb: string;
    personChargeName: string;
    personChargePhoneNumber: number;
  };
  gps?: {
    name: string;
    url: string;
    username: string;
    password: string;
  };
  prices?: {
    totalPrice?: number;
    subTotalPrice?: number;
  };
}

const INITIAL_DOCUMENTS = [
  {
    type: 'Factura',
    document: '',
  },
  {
    type: 'Comprobante de pago cliente',
    document: '',
  },
  {
    type: 'Comprobante de pago transportista',
    document: '',
  },
  {
    type: 'Comprobante mercancía recibida',
    document: '',
  },
  {
    type: 'Comprobante mercancía entregada',
    document: '',
  },
  {
    type: 'Cotización',
    document: '',
  },
  {
    type: 'Orden de compra',
    document: '',
  },
  {
    type: 'Carta porte',
    document: '',
  },
  {
    type: 'Comprobante de pago',
    document: '',
  },
  {
    type: 'Albarán',
    document: '',
  },
  {
    type: 'Recibo de mercancía',
    document: '',
  },
  {
    type: 'Entrega de mercancía',
    document: '',
  },
  {
    type: 'Póliza de seguro',
    document: '',
  },
  {
    type: 'Otros documentos',
    document: '',
  },
];

export const TravelDetailEdit = () => {
  const router = useHistory();
  const { id } = useParams<{
    id: string;
  }>();
  const [status, setStatus] = useState('idle');
  const [mutationStatus, setMutationStatus] = useState('idle');
  const [travel, setTravel] = useState<ITravel>({
    documentation: INITIAL_DOCUMENTS,
    origin: {
      id: '',
      city: '',
      state: '',
      latitude: 0,
      longitude: 0,
      address: '',
      street: '',
      zipCode: '',
      suburb: '',
      personChargeName: '',
      personChargePhoneNumber: 0,
    },
    destination: {
      id: '',
      city: '',
      state: '',
      latitude: 0,
      longitude: 0,
      address: '',
      street: '',
      zipCode: '',
      suburb: '',
      personChargeName: '',
      personChargePhoneNumber: 0,
    },
    gps: {
      name: '',
      url: '',
      username: '',
      password: '',
    },
    prices: {
      subTotalPrice: 0,
      totalPrice: 0,
    },
  });
  const isLoading = status === 'idle' || status === 'pending';
  const isMutationLoading = mutationStatus === 'pending';

  useEffect(() => {
    async function getQuery() {
      setStatus('pending');

      try {
        const { data } = (await API.graphql({
          query: GetFreight,
          variables: {
            id,
          },
        })) as any;

        const documentation = removeDuplicates(
          INITIAL_DOCUMENTS,
          data.getFreight.documentation || []
        );

        if (data) {
          setTravel({
            type: data.getFreight?.type,
            documentation,
            origin: {
              id: data.getFreight.origin.id,
              city: data.getFreight.origin.city,
              state: data.getFreight.origin.state,
              latitude: data.getFreight.origin.latitude,
              longitude: data.getFreight.origin.longitude,
              address: data.getFreight.origin.address,
              street: data.getFreight.origin.street || '',
              zipCode: data.getFreight.origin.zipCode || '',
              suburb: data.getFreight.origin.suburb || '',
              personChargeName: data.getFreight.origin.personCharge?.name || '',
              personChargePhoneNumber:
                data.getFreight.origin.personCharge?.phoneNumber || '',
            },
            destination: {
              id: data.getFreight.destination.id,
              city: data.getFreight.destination.city,
              state: data.getFreight.destination.state,
              latitude: data.getFreight.destination.latitude,
              longitude: data.getFreight.destination.longitude,
              address: data.getFreight.destination.address,
              street: data.getFreight.destination.street || '',
              zipCode: data.getFreight.destination.zipCode || '',
              suburb: data.getFreight.destination.suburb || '',
              personChargeName:
                data.getFreight.destination.personCharge?.name || '',
              personChargePhoneNumber:
                data.getFreight.destination.personCharge?.phoneNumber || '',
            },
            gps: {
              name: data.getFreight.gps?.name,
              url: data.getFreight.gps?.url,
              username: data.getFreight.gps?.username,
              password: data.getFreight.gps?.password,
            },
            prices: {
              totalPrice: data.getFreight?.totalPrice,
              subTotalPrice: data.getFreight?.subTotalPrice,
            },
          });
        }

        setStatus('resolved');
      } catch {
        setStatus('rejected');
      }
    }

    getQuery();
  }, [id]);

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    event.preventDefault();

    const { name, value } = event.target;

    const [propertyKey, propertyVal] = name.split('.');

    setTravel(prevValues => ({
      ...prevValues,
      [propertyKey]: {
        // @ts-ignore
        ...prevValues[propertyKey],
        [propertyVal]: value,
      },
    }));
  };

  const handleFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();

    const { name, files } = event.target;

    if (!name || !files || files?.length === 0) return;

    const file = files[0];

    if (!file.type.includes('image') && !file.type.includes('pdf')) return;

    setTravel(prevValues => {
      const documentIndex = prevValues.documentation?.findIndex(
        document => document.type === name
      );

      // @ts-ignore
      prevValues.documentation[documentIndex] = {
        type: name,
        document: file,
      };

      return {
        ...prevValues,
        documentation: [...(prevValues.documentation || [])],
      };
    });
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setMutationStatus('pending');

    const currentDocumentation = travel.documentation?.map(
      ({ type, document }) => ({
        type,
        document: (document as File)?.name
          ? `freight/${id}/documentation/${(document as File)?.name}`
          : document,
      })
    );
    const filesDocumentation =
      travel.documentation
        ?.filter(({ document }) => document instanceof File)
        .map(({ document }) => ({
          key: `freight/${id}/documentation/${(document as File)?.name}`,
          value: document,
        })) || [];

    try {
      const [updateOrigin, updateDestination, updateFreight] =
        await Promise.all([
          API.graphql({
            query: UpdateLocation,
            variables: {
              input: {
                id: travel.origin.id,
                street: travel.origin.street,
                zipCode: travel.origin.zipCode,
                suburb: travel.origin.suburb,
                personCharge: {
                  name: travel.origin.personChargeName,
                  phoneNumber: travel.origin.personChargePhoneNumber,
                },
              },
            },
          }) as any,
          API.graphql({
            query: UpdateLocation,
            variables: {
              input: {
                id: travel.destination.id,
                street: travel.destination.street,
                zipCode: travel.destination.zipCode,
                suburb: travel.destination.suburb,
                personCharge: {
                  name: travel.destination.personChargeName,
                  phoneNumber: travel.destination.personChargePhoneNumber,
                },
              },
            },
          }) as any,
          API.graphql({
            query: UpdateFreight,
            variables: {
              input: {
                id,
                subTotalPrice: travel.prices?.subTotalPrice || undefined,
                totalPrice: travel.prices?.totalPrice || undefined,
                gps: {
                  name: travel.gps?.name || '',
                  url: travel.gps?.url || '',
                  username: travel.gps?.username || '',
                  password: travel.gps?.password || '',
                },
                documentation: currentDocumentation,
              },
            },
          }) as any,
        ]);

      if (
        updateOrigin.data.updateLocation?.id &&
        updateDestination.data.updateLocation?.id &&
        updateFreight.data.updateFreight?.id
      ) {
        Promise.all(
          filesDocumentation.map(
            ({ key, value }) => Storage.put(key, value) as Promise<string>
          )
        )
          .then(() => {
            router.push(`/travel/${id}`);
          })
          .catch(error => {
            console.error(error);

            alert('Error uploading Files! Check console');
            return;
          });
      }
    } catch (error) {
      console.error(error);

      alert('Error! Check console');
      return;
    }
  };

  if (isLoading) {
    return <div />;
  }

  return (
    <Wrapper>
      <form
        method="POST"
        onSubmit={isMutationLoading ? undefined : handleSubmit}
      >
        <div className="section">
          <h3 className="section-name">Origen</h3>
          <div className="box">
            <ul className="box-items">
              <li className="item">
                <h4 className="item-name">Ciudad</h4>
                <p className="item-value">{travel.origin.city}</p>
              </li>
              <li className="item">
                <h4 className="item-name">Estado</h4>
                <p className="item-value">{travel.origin.state}</p>
              </li>
              <li className="item">
                <h4 className="item-name">Latitud</h4>
                <p className="item-value">{travel.origin.latitude}</p>
              </li>
              <li className="item">
                <h4 className="item-name">Longitud</h4>
                <p className="item-value">{travel.origin.longitude}</p>
              </li>
              <li className="item">
                <h4 className="item-name">Dirección</h4>
                <p className="item-value">{travel.origin.address}</p>
              </li>
              <li className="item">
                <h4 className="item-name">Calle y número</h4>
                <Input
                  name="origin.street"
                  value={travel.origin.street}
                  onChange={handleChange}
                />
              </li>
              <li className="item">
                <h4 className="item-name">Código postal</h4>
                <Input
                  name="origin.zipCode"
                  value={travel.origin.zipCode}
                  onChange={handleChange}
                />
              </li>
              <li className="item">
                <h4 className="item-name">Colonia</h4>
                <Input
                  name="origin.suburb"
                  value={travel.origin.suburb}
                  onChange={handleChange}
                />
              </li>
              <li className="item">
                <h4 className="item-name">Nombre de persona a cargo</h4>
                <Input
                  name="origin.personChargeName"
                  value={travel.origin.personChargeName}
                  onChange={handleChange}
                />
              </li>
              <li className="item">
                <h4 className="item-name">Teléfono de persona a cargo</h4>
                <Input
                  name="origin.personChargePhoneNumber"
                  value={travel.origin.personChargePhoneNumber}
                  onChange={handleChange}
                />
              </li>
            </ul>
          </div>
        </div>
        <div className="section">
          <h3 className="section-name">Destino</h3>
          <div className="box">
            <ul className="box-items">
              <li className="item">
                <h4 className="item-name">Ciudad</h4>
                <p className="item-value">{travel.destination.city}</p>
              </li>
              <li className="item">
                <h4 className="item-name">Estado</h4>
                <p className="item-value">{travel.destination.state}</p>
              </li>
              <li className="item">
                <h4 className="item-name">Latitud</h4>
                <p className="item-value">{travel.destination.latitude}</p>
              </li>
              <li className="item">
                <h4 className="item-name">Longitud</h4>
                <p className="item-value">{travel.destination.longitude}</p>
              </li>
              <li className="item">
                <h4 className="item-name">Dirección</h4>
                <p className="item-value">{travel.destination.address}</p>
              </li>
              <li className="item">
                <h4 className="item-name">Calle y número</h4>
                <Input
                  name="destination.street"
                  value={travel.destination.street}
                  onChange={handleChange}
                />
              </li>
              <li className="item">
                <h4 className="item-name">Código postal</h4>
                <Input
                  name="destination.zipCode"
                  value={travel.destination.zipCode}
                  onChange={handleChange}
                />
              </li>
              <li className="item">
                <h4 className="item-name">Colonia</h4>
                <Input
                  name="destination.suburb"
                  value={travel.destination.suburb}
                  onChange={handleChange}
                />
              </li>
              <li className="item">
                <h4 className="item-name">Nombre de persona a cargo</h4>
                <Input
                  name="destination.personChargeName"
                  value={travel.destination.personChargeName}
                  onChange={handleChange}
                />
              </li>
              <li className="item">
                <h4 className="item-name">Teléfono de persona a cargo</h4>
                <Input
                  name="destination.personChargePhoneNumber"
                  value={travel.destination.personChargePhoneNumber}
                  onChange={handleChange}
                />
              </li>
            </ul>
          </div>
        </div>
        <div className="section">
          <h3 className="section-name">GPS</h3>
          <div className="box">
            <ul className="box-items">
              <li className="item">
                <h4 className="item-name">Nombre</h4>
                <Input
                  name="gps.name"
                  value={travel.gps?.name}
                  onChange={handleChange}
                />
              </li>
              <li className="item">
                <h4 className="item-name">URL</h4>
                <Input
                  name="gps.url"
                  value={travel.gps?.url}
                  onChange={handleChange}
                />
              </li>
              <li className="item">
                <h4 className="item-name">Nombre de usuario</h4>
                <Input
                  name="gps.username"
                  value={travel.gps?.username}
                  onChange={handleChange}
                />
              </li>
              <li className="item">
                <h4 className="item-name">Contraseña</h4>
                <Input
                  name="gps.password"
                  value={travel.gps?.password}
                  onChange={handleChange}
                />
              </li>
            </ul>
          </div>
        </div>
        {travel.type === 'ENTERPRISE' && (
          <div className="section">
            <h3 className="section-name">ENTERPRISE</h3>
            <div className="box">
              <ul className="box-items">
                <li className="item">
                  <h4 className="item-name">Sub Total</h4>
                  <Input
                    name="prices.subTotalPrice"
                    value={travel.prices?.subTotalPrice || ''}
                    onChange={handleChange}
                  />
                </li>
                <li className="item">
                  <h4 className="item-name">Total</h4>
                  <Input
                    name="prices.totalPrice"
                    value={travel.prices?.totalPrice || ''}
                    onChange={handleChange}
                  />
                </li>
              </ul>
            </div>
          </div>
        )}
        <div className="section">
          <h3 className="section-name">Documentación</h3>
          <div className="box">
            <ul className="box-items">
              {travel.documentation?.map(({ type, document }, index) => (
                <li className="item" key={index}>
                  <p className="item-name">
                    {type} {document ? '✅' : '❌'}
                  </p>
                  <input
                    type="file"
                    accept="image/*,application/pdf"
                    name={type}
                    onChange={handleFile}
                  />
                </li>
              ))}
            </ul>
          </div>
        </div>
        <button
          type="submit"
          className="save-button"
          disabled={isMutationLoading}
        >
          SAVE IT NOW!
        </button>
      </form>
    </Wrapper>
  );
};
