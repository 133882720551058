export const STATUS = [
  'PAYMENT_ACCEPTED',
  'SERVICE_IN_PROGRESS',
  'SERVICE_COMPLETED',
  'SERVICE_CANCELLED',
];

export const FREIGHTS_STATUS = [
  {
    status: 'ALL_STATUS',
    text: 'Todos',
  },
  {
    status: 'PAYMENT_ACCEPTED',
    text: 'Reservado',
    icon: 'today',
    color: 'chateauGreen',
    backgroundColor: 'lightChateauGreen',
  },
  {
    status: 'SERVICE_IN_PROGRESS',
    text: 'En curso',
    icon: 'directions',
    color: 'orange',
    backgroundColor: 'lightOrange',
  },
  {
    status: 'SERVICE_COMPLETED',
    text: 'Finalizado con éxito',
    icon: 'factcheck',
    color: 'primary',
    backgroundColor: 'lightPrimary',
  },
  {
    status: 'SERVICE_CANCELLED',
    text: 'Cancelado',
    icon: 'cancelschedulesend',
    color: 'valencia',
    backgroundColor: 'lightValencia',
  },
];
