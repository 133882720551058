import React, { useState, useEffect } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import { API, Storage, Auth } from 'aws-amplify';

import { FreightDriverContent, TruckContent } from './styles';
import { Status } from '../FreightDriverTruck/styles';
import { Item } from '../FreightDrivers/styles';
import { Icon } from 'components/Icon';
import { Button } from 'components/Button';
import { GetFreightDriver } from './graphql';
import { TRUCK_STATUS } from '../FreightDrivers/constants';
import { formatDate } from 'lib/date';

interface ITrucks {
  id: string;
  brand: string;
  model: string;
  yearModel: string;
  status: string;
}

interface IFreightDriver {
  id: string;
  firstName: string;
  lastName: string;
  companyName: string;
  email: string;
  phoneNumber: string;
  cities: string[];
  state: string;
  businessName: string;
  RFC: string;
  companyAddress: string;
  identificationDocumentPhoto: string;
  companyPicture: string;
  bankName: string;
  bankAccountNumber: string;
  interbankCode: string;
  createdAt: string;
  trucks: {
    items: ITrucks[];
    nextToken: string | null;
  };
}

export const FreightDriver = () => {
  const { id } = useParams<{ id: string }>();
  const router = useHistory();
  const [freightDriver, setFreightDriver] = useState<IFreightDriver>({
    id: '',
    firstName: '',
    lastName: '',
    companyName: '',
    email: '',
    phoneNumber: '',
    cities: [],
    state: '',
    businessName: '',
    RFC: '',
    companyAddress: '',
    identificationDocumentPhoto: '',
    companyPicture: '',
    bankName: '',
    bankAccountNumber: '',
    interbankCode: '',
    createdAt: '',
    trucks: {
      items: [],
      nextToken: null,
    },
  });
  const [nextToken, setNextToken] = useState<string | null>(null);
  const [pictures, setPictures] = useState<string[]>([]);
  const [isDotsOptionOpen, setIsDotsOptionOpen] = useState(false);

  useEffect(() => {
    const handleFreightDriver = async () => {
      const { data } = (await API.graphql({
        query: GetFreightDriver,
        variables: {
          id,
          nextToken,
        },
      })) as any;

      setFreightDriver(prevFreightDriver => ({
        ...data.getFreightDriver,
        trucks: {
          items: [
            ...prevFreightDriver?.trucks.items,
            ...(data.getFreightDriver?.trucks.items || []),
          ],
          nextToken: data.getFreightDriver?.trucks.nextToken,
        },
      }));
    };

    handleFreightDriver();
  }, [id, nextToken]);

  useEffect(() => {
    const getPictures = async () => {
      const comapanyPicture = (await Storage.get(
        freightDriver?.companyPicture
      )) as string;
      const identificationDocumentPhoto = (await Storage.get(
        freightDriver?.identificationDocumentPhoto
      )) as string;

      setPictures([comapanyPicture, identificationDocumentPhoto]);
    };

    if (
      freightDriver?.companyPicture &&
      freightDriver?.identificationDocumentPhoto
    ) {
      getPictures();
    }
  }, [
    freightDriver?.companyPicture,
    freightDriver?.identificationDocumentPhoto,
  ]);

  const handleNextToken = (nextToken: string | null) => {
    setNextToken(nextToken);
  };

  const handleDeleteFreightDriver = async () => {
    if (freightDriver.trucks.items.length !== 0) {
      alert('Primero se debe de eliminar los camiones del transportista.');
      return;
    }

    const params = {
      body: {
        username: freightDriver.id,
        groupname: 'FreightDriver',
      },
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${(await Auth.currentSession())
          .getAccessToken()
          .getJwtToken()}`,
      },
    };

    Promise.all([
      API.post('AdminQueries', '/disableUser', params),
      API.post('AdminQueries', '/removeUserFromGroup', params),
    ]).then(() => {
      router.push('/freights');
    });
  };

  if (!freightDriver) {
    return <div />;
  }

  return (
    <>
      <FreightDriverContent>
        <div className="header">
          <div className="content">
            <div className="logo">
              <Icon iconType="business" />
            </div>
            <div className="title">
              <p>
                {freightDriver.firstName} {freightDriver.lastName}
              </p>
              <hr />
              {freightDriver.createdAt && (
                <span>{formatDate(freightDriver.createdAt)}</span>
              )}
            </div>
          </div>
          {freightDriver.id && (
            <div className="dots-content">
              <div
                className="dots"
                onClick={() => setIsDotsOptionOpen(!isDotsOptionOpen)}
              >
                <div />
                <div />
                <div />
              </div>
              {isDotsOptionOpen && (
                <div className="dots-buttons">
                  <div
                    className="dots-button"
                    onClick={handleDeleteFreightDriver}
                  >
                    Borrar empresa
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
        <div className="user-content">
          <p>
            Nombre de la compañia: <span>{freightDriver.companyName}</span>
          </p>
          <p>
            Correo Electrónico: <span>{freightDriver.email}</span>
          </p>
          <p>
            Numero de teléfono: <span>{freightDriver.phoneNumber}</span>
          </p>
          <p>
            Ubicaciones:{' '}
            <span>
              {freightDriver.cities?.join(', ')} - {freightDriver.state}
            </span>
          </p>
          <p>
            Razón social: <span>{freightDriver.businessName}</span>
          </p>
          <p>
            RFC: <span>{freightDriver.RFC}</span>
          </p>
          <p>
            Dirección de compañia: <span>{freightDriver.companyAddress}</span>
          </p>
          <p>
            Nombre de banco: <span>{freightDriver.bankName}</span>
          </p>
          <p>
            Numero de cuenta: <span>{freightDriver.bankAccountNumber}</span>
          </p>
          <p>
            Clave interbancaria: <span>{freightDriver.interbankCode}</span>
          </p>
          <p>Logotipo de la empresa:</p>
          {pictures[0] && (
            <a href={pictures[0]}>
              <img src={pictures[0]} alt={freightDriver.firstName} />
            </a>
          )}
          <p>Foto INE/IFE/Pasaporte:</p>
          {pictures[1] && (
            <a href={pictures[1]}>
              <img src={pictures[1]} alt={freightDriver.firstName} />
            </a>
          )}
        </div>
      </FreightDriverContent>
      <TruckContent>
        {freightDriver.trucks.items.map(truck => (
          <Link className="link" to={`/trucks/${truck.id}`} key={truck.id}>
            <Item>
              <div className="box-title">
                <div className="box">
                  <div className="business">
                    <Icon iconType="business" />
                  </div>
                </div>
                <div className="title">
                  {truck.brand} {truck.model} {truck.yearModel}
                </div>
              </div>
              <div className="box-content">
                <Status
                  color={
                    TRUCK_STATUS.find(option => option.status === truck.status)
                      ?.color
                  }
                >
                  <Icon
                    iconType={
                      TRUCK_STATUS.find(
                        option => option.status === truck.status
                      )?.icon
                    }
                  />
                  <span>
                    {
                      TRUCK_STATUS.find(
                        option => option.status === truck.status
                      )?.text
                    }
                  </span>
                </Status>
                <div className="arrow">
                  <Icon iconType="arrow_forward" />
                </div>
              </div>
            </Item>
          </Link>
        ))}
        {freightDriver.trucks?.nextToken && (
          <Button
            id="limit-button"
            type="button"
            variant="outlined"
            text="Cargar más"
            textColor="primary"
            onClick={() => handleNextToken(freightDriver.trucks?.nextToken)}
          />
        )}
      </TruckContent>
    </>
  );
};
