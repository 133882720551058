import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { API, Storage } from 'aws-amplify';

import { Wrapper } from '../FreightDriverTruck/styles';
import { GetCost } from '../Cost/graphql';
import { PLAN_TITLE } from '../Cost/constants';
import { Icon } from '../../Icon';

interface ICost {
  id?: string;
  boxType: string;
  boxWidth: string;
  boxHeight: string;
  boxLength: string;
  axles: string;
  fuelType: string;
  fuelEfficiency: string;
  measuresPhoto: string;
  utilities: {
    plan: 'basic' | 'normal' | 'shared' | 'prime';
    percentage: string;
    discount: string;
    insuranceDiscount: string;
    maneuversPrice: string;
  }[];
}

export const CostDetail = () => {
  const router = useHistory();
  const { costId } = useParams<{
    costId: string;
  }>();
  const [status, setStatus] = useState('idle');
  const [cost, setCost] = useState<ICost>({
    id: '',
    boxType: 'Plataforma',
    boxWidth: '',
    boxHeight: '',
    boxLength: '',
    axles: '',
    fuelType: 'diesel',
    fuelEfficiency: '',
    measuresPhoto: '',
    utilities: [
      {
        plan: 'basic',
        percentage: '0',
        discount: '0',
        insuranceDiscount: '0',
        maneuversPrice: '0',
      },
      {
        plan: 'normal',
        percentage: '0',
        discount: '0',
        insuranceDiscount: '0',
        maneuversPrice: '0',
      },
      {
        plan: 'shared',
        percentage: '0',
        discount: '0',
        insuranceDiscount: '0',
        maneuversPrice: '0',
      },
      {
        plan: 'prime',
        percentage: '0',
        discount: '0',
        insuranceDiscount: '0',
        maneuversPrice: '0',
      },
    ],
  });
  const [isDotsOptionOpen, setIsDotsOptionOpen] = useState(false);
  const isLoading = status === 'idle' || status === 'pending';

  useEffect(() => {
    async function getQuery() {
      setStatus('pending');

      try {
        const { data } = (await API.graphql({
          query: GetCost,
          variables: {
            id: costId,
          },
        })) as any;

        if (data) {
          let measuresPhoto;

          if (data.getCost.measuresPhoto) {
            measuresPhoto = (await Storage.get(
              data.getCost.measuresPhoto as string
            )) as Promise<string>;
          }

          setCost({
            ...data.getCost,
            measuresPhoto,
          });
        }

        setStatus('resolved');
      } catch {
        setStatus('rejected');
      }
    }

    if (costId !== '0') {
      getQuery();
    }
  }, [costId]);

  if (isLoading) {
    return <div />;
  }

  return (
    <Wrapper>
      <div className="section">
        <div className="first-card">
          <figure className="icon">
            <Icon iconType="localshipping" />
          </figure>
          <p className="text">
            {cost.boxType} <span>&nbsp;·&nbsp;</span> {cost.boxLength} metros
          </p>
          <div className="dots-content">
            <div
              className="dots"
              onClick={() => setIsDotsOptionOpen(!isDotsOptionOpen)}
            >
              <div />
              <div />
              <div />
            </div>
            {isDotsOptionOpen && (
              <div className="dots-buttons">
                <div
                  className="dots-button"
                  onClick={() => {
                    router.push(`/cost/create-or-edit/${costId}`);
                  }}
                >
                  <span>Editar costo</span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="section">
        <h3 className="section-name">Datos generales</h3>
        <div className="box">
          <ul className="box-items">
            <li className="item">
              <h4 className="item-name">Tipo de caja</h4>
              <p className="item-value">{cost.boxType}</p>
            </li>
            <li className="item">
              <p className="item-name">Ancho de caja</p>
              <p className="item-value">{cost.boxWidth}</p>
            </li>
            <li className="item">
              <p className="item-name">Alto de caja</p>
              <p className="item-value">{cost.boxHeight}</p>
            </li>
            <li className="item">
              <p className="item-name">Largo de caja</p>
              <p className="item-value">{cost.boxLength}</p>
            </li>
            <li className="item">
              <p className="item-name">Ejes</p>
              <p className="item-value">{cost.axles}</p>
            </li>
            <li className="item">
              <h4 className="item-name">Tipo de gasolina</h4>
              <p className="item-value">{cost.fuelType}</p>
            </li>
            <li className="item">
              <p className="item-name">Rendimiento</p>
              <p className="item-value">{cost.fuelEfficiency}</p>
            </li>
            <li className="item">
              <h4 className="item-name">Imagen</h4>
              <p className="item-value">
                <a href={cost.measuresPhoto}>
                  <img src={cost.measuresPhoto} />
                </a>
              </p>
            </li>
          </ul>
        </div>
      </div>
      {cost.utilities.map((utility, index) => (
        <div className="section" key={index}>
          <h3 className="section-name">{PLAN_TITLE[utility.plan]}</h3>
          <div className="box">
            <ul className="box-items">
              <li className="item">
                <p className="item-name">Porcentaje de utilidad</p>
                <p className="item-value">{cost.utilities[index].percentage}</p>
              </li>
              <li className="item">
                <p className="item-name">Descuento</p>
                <p className="item-value">{cost.utilities[index].discount}</p>
              </li>
              <li className="item">
                <p className="item-name">Descuento en seguro de carga</p>
                <p className="item-value">
                  {cost.utilities[index].insuranceDiscount}
                </p>
              </li>
              <li className="item">
                <p className="item-name">Maniobras de carga</p>
                <p className="item-value">
                  {cost.utilities[index].maneuversPrice}
                </p>
              </li>
            </ul>
          </div>
        </div>
      ))}
    </Wrapper>
  );
};
