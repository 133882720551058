import styled, { css } from 'styled-components';

export const FreightDriverContent = styled.div`
  ${({ theme }) => css`
    margin: 0 auto;
    margin-top: 150px;
    width: 1161px;
    padding: 21px 47px;
    background-color: ${theme.colors.white};
    border-radius: 20px;

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid ${theme.colors.gallery};
      padding-bottom: 21px;
      position: relative;

      .content {
        display: flex;
        align-items: center;

        .logo {
          width: 33px;
          height: 33px;
          background-color: rgba(26, 91, 250, 0.1);
          border-radius: 9999px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 14px;

          svg {
            width: 20px;
            height: 20px;
            fill: ${theme.colors.primary};
          }
        }

        .title {
          display: flex;
          align-items: center;
          font-size: ${theme.fonts.base};
          color: ${theme.colors.secondary};

          p {
            font-family: 'Gordita-Bold';
            margin: 0;
          }

          hr {
            height: 20px;
            height: 20px;
            margin: 0px 10px;
            opacity: 0.7;
          }

          span {
            font-family: 'Gordita-Regular';
          }
        }
      }

      .dots-content {
        position: absolute;
        right: 10px;
        top: 10px;

        .dots {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          grid-gap: 2px;
          cursor: pointer;
          width: 20px;
          height: 20px;

          div {
            background-color: ${theme.colors.secondary};
            width: 3px;
            height: 3px;
            border-radius: 9999px;
          }
        }

        .dots-buttons {
          width: 250px;
          position: absolute;
          top: 25px;
          right: 10px;
          background-color: white;
          border-radius: 10px;

          .dots-button {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 50px;
            cursor: pointer;
            font-size: 18px;
            color: ${theme.colors.secondary};
            border: 1px solid ${theme.colors.gallery};
          }
        }
      }
    }

    .user-content {
      p {
        color: ${theme.colors.neutral};

        span {
          color: ${theme.colors.secondary};
        }
      }

      img {
        width: 255px;
        height: 186px;
        border-radius: 10px;
      }
    }
  `}
`;

export const TruckContent = styled.div`
  width: 1161px;
  min-height: 467px;
  margin: 93px auto;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 20px;

  .link {
    text-decoration: none;

    & > div {
      margin: 0;
      border-radius: 0px;
    }
  }

  & > :first-child > div {
    border-radius: 20px 20px 0px 0px;
  }

  & > :last-child > div {
    border-radius: 0px 0px 20px 20px;
  }

  button {
    margin: 12px auto;
  }
`;
