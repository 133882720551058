import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import algoliasearch from 'algoliasearch/lite';
import { InstantSearch, Configure } from 'react-instantsearch-dom';

import { Icon } from 'components/Icon';
import { formatDate } from 'lib/date';
import { timestampUnix } from 'utils';
import { TRUCK_STATUS } from '../FreightDrivers/constants';
import { STATES_CITIES, BOX_TYPE } from './constants';
import { Status } from '../FreightDriverTruck/styles';
import {
  Wrapper,
  Text,
  Item,
  InfiniteHitsWrapper,
} from '../FreightDrivers/styles';

const searchClient = algoliasearch(
  process.env.REACT_APP_APPLICATION_ID || '',
  process.env.REACT_APP_API_KEY || ''
);
const index = searchClient.initIndex(
  process.env.REACT_APP_INDEX_FREIGHT_DRIVER_TRUCKS || ''
);

export const Trucks = () => {
  const [amountItems, setAmountItems] = useState(0);
  const [filterValues, setFilterValues] = useState({
    date: '',
    status: 'ALL_STATUS',
    state: 'TODOS',
    city: '',
    tons: null,
    box: '',
  });
  const states = Object.keys(STATES_CITIES);
  const cities = Object.values(STATES_CITIES[filterValues.state]);

  useEffect(() => {
    const getAmountItems = async () => {
      const query = await index.search('');

      setAmountItems(query.nbHits);
    };

    getAmountItems();
  }, []);

  const handleFilterValues = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = event.target;

    if (name === 'state' && value === 'TODOS' && filterValues.city) {
      setFilterValues(prevValues => ({
        ...prevValues,
        city: '',
      }));
    }

    setFilterValues(prevValues => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleFilter = () => {
    let filter = '';

    if (filterValues.date) {
      const facet = `createdAt_timestamp:${timestampUnix(
        filterValues.date,
        'start'
      )} TO ${timestampUnix(filterValues.date, 'end')}`;

      filter = facet + filter;
    }

    if (filterValues.status) {
      const facet = `status:${filterValues.status}`;

      if (filterValues.status === 'ALL_STATUS') {
        filter = filter.replace(facet, '');
      } else if (filter.length > 0) {
        filter = filter + ` AND ${facet}`;
      } else {
        filter = facet;
      }
    }

    if (filterValues.state) {
      const facet = `freightDriver.state:'${filterValues.state}'`;

      if (filterValues.state === 'TODOS') {
        filter = filter.replace(facet, '');
      } else if (filter.length > 0) {
        filter = filter + ` AND ${facet}`;
      } else {
        filter = facet;
      }
    }

    if (filterValues.city) {
      const facet = `freightDriver.cities:'${filterValues.city}'`;

      if (!filterValues.city) {
        filter = filter.replace(facet, '');
      } else if (filter.length > 0) {
        filter = filter + ` AND ${facet}`;
      } else {
        filter = facet;
      }
    }

    if (filterValues.tons) {
      const tons = parseInt(filterValues.tons || '', 10);
      const facet = `tons:'${tons}'`;

      if (tons === 0) {
        filter = filter.replace(facet, '');
      } else if (filter.length > 0) {
        filter = filter + ` AND ${facet}`;
      } else {
        filter = facet;
      }
    }

    if (filterValues.box) {
      const facet = `box.type:'${filterValues.box}'`;

      if (filterValues.box === 'TODOS') {
        filter = filter.replace(facet, '');
      } else if (filter.length > 0) {
        filter = filter + ` AND ${facet}`;
      } else {
        filter = facet;
      }
    }

    return filter;
  };

  return (
    <InstantSearch
      indexName={process.env.REACT_APP_INDEX_FREIGHT_DRIVER_TRUCKS || ''}
      searchClient={searchClient}
    >
      <Configure
        filters={handleFilter()}
        hitsPerPage={20}
        analytics={false}
        distinct
      />
      <Wrapper>
        <Text>
          <p>Camiones</p> <hr /> <span>{amountItems}</span>
          <input
            type="date"
            name="date"
            value={filterValues.date}
            onChange={handleFilterValues}
          />
          <select
            name="status"
            onChange={handleFilterValues}
            value={filterValues.status}
          >
            {TRUCK_STATUS.map((truck, index) => (
              <option key={index} value={truck.status}>
                {truck.text}
              </option>
            ))}
          </select>
          <select
            name="state"
            placeholder="Estado"
            onChange={handleFilterValues}
            value={filterValues.state}
          >
            {states.map((state, index) => (
              <option key={index} value={state}>
                {state}
              </option>
            ))}
          </select>
          {filterValues.state !== 'TODOS' && (
            <select
              name="city"
              placeholder="Ciudad"
              onChange={handleFilterValues}
              value={filterValues.city}
            >
              {(cities as string[]).map((city, index) => (
                <option key={index} value={city}>
                  {city}
                </option>
              ))}
            </select>
          )}
          <select
            name="tons"
            placeholder="Capacidad"
            onChange={handleFilterValues}
            value={filterValues.tons || 0}
          >
            {new Array(81).fill(0).map((_, index) => (
              <option key={index} value={index}>
                {index === 0 ? 'TODOS' : `${index} Toneladas`}
              </option>
            ))}
          </select>
          <select
            name="box"
            placeholder="Tipo de caja"
            onChange={handleFilterValues}
            value={filterValues.box}
          >
            {BOX_TYPE.map((box, index) => (
              <option key={index} value={box}>
                {box}
              </option>
            ))}
          </select>
        </Text>
        <InfiniteHitsWrapper
          hitComponent={(props: any) => (
            <Link
              className="link"
              to={`/trucks/${props.hit.objectID}`}
              key={props.hit.objectID}
            >
              <Item>
                <div className="box-title">
                  <div className="box">
                    <div className="business">
                      <Icon iconType="business" />
                    </div>
                  </div>
                  <div className="title">
                    <p>
                      {props.hit.brand} {props.hit.model} {props.hit.yearModel}
                    </p>
                    <hr />
                    <span>
                      {props.hit.freightDriver.state}
                      <br />
                      {formatDate(props.hit.createdAt)}
                    </span>
                  </div>
                </div>
                <div className="box-content">
                  <Status
                    color={
                      TRUCK_STATUS.find(
                        option => option.status === props.hit.status
                      )?.color
                    }
                  >
                    <Icon
                      iconType={
                        TRUCK_STATUS.find(
                          option => option.status === props.hit.status
                        )?.icon
                      }
                    />
                    <span>
                      {
                        TRUCK_STATUS.find(
                          option => option.status === props.hit.status
                        )?.text
                      }
                    </span>
                  </Status>
                  <div className="arrow">
                    <Icon iconType="arrow_forward" />
                  </div>
                </div>
              </Item>
            </Link>
          )}
        />
      </Wrapper>
    </InstantSearch>
  );
};
