import React, { FunctionComponent } from 'react';

import { Add } from './svg/add';
import { Assignment } from './svg/assignment';
import { Check } from './svg/check';
import { Close } from './svg/close';
import { Exit } from './svg/exit';
import { Expand } from './svg/expand';
import { Face } from './svg/face';
import { FacebookCircle } from './svg/facebookcircle';
import { Back } from './svg/back';
import { Highlight } from './svg/highlight';
import { Gps } from './svg/gps';
import { Instagram } from './svg/instagram';
import { PrimaryLogo } from './svg/primarylogo';
import { SecondaryLogo } from './svg/secondarylogo';
import { LogoWhite } from './svg/logowhite';
import { Map } from './svg/map';
import { Menu } from './svg/menu';
import { Money } from './svg/money';
import { LocalShipping } from './svg/localshipping';
import { Pin } from './svg/pin';
import { Security } from './svg/security';
import { Send } from './svg/send';
import { Subtract } from './svg/subtract';
import { Square } from './svg/square';
import { Timeline } from './svg/timeline';
import { Trips } from './svg/trips';
import { Today } from './svg/today';
import { Waves } from './svg/waves';
import { Widgets } from './svg/widgets';
import { Facebook } from './svg/facebook';
import { Google } from './svg/google';
import { Business } from './svg/business';
import { ArrowForward } from './svg/arrow_forward';
import { Directions } from './svg/directions';
import { Timelapse } from './svg/timelapse';
import { StoreMallDirectory } from './svg/storemalldirectory';
import { Time } from './svg/time';
import { Gesture } from './svg/gesture';
import { EmojiPeople } from './svg/emoji_people';
import { Message } from './svg/message';
import { DoneAll } from './svg/doneall';
import { Star } from './svg/star';
import { Factcheck } from './svg/factcheck';
import { CancelScheduleSend } from './svg/cancelschedulesend';
import { ThumbDown } from './svg/thumbdown';
import { RemoveCircle } from './svg/removecircle';

export interface Props {
  iconType: string | undefined;
}

export const Icon: FunctionComponent<Props> = ({ iconType }) => {
  if (iconType === 'add') {
    return <Add />;
  }

  if (iconType === 'assignment') {
    return <Assignment />;
  }

  if (iconType === 'check') {
    return <Check />;
  }

  if (iconType === 'close') {
    return <Close />;
  }

  if (iconType === 'exit') {
    return <Exit />;
  }

  if (iconType === 'expand') {
    return <Expand />;
  }

  if (iconType === 'face') {
    return <Face />;
  }

  if (iconType === 'facebookcircle') {
    return <FacebookCircle />;
  }

  if (iconType === 'back') {
    return <Back />;
  }

  if (iconType === 'highlight') {
    return <Highlight />;
  }

  if (iconType === 'gps') {
    return <Gps />;
  }

  if (iconType === 'instagram') {
    return <Instagram />;
  }

  if (iconType === 'primarylogo') {
    return <PrimaryLogo />;
  }

  if (iconType === 'secondarylogo') {
    return <SecondaryLogo />;
  }

  if (iconType === 'logowhite') {
    return <LogoWhite />;
  }

  if (iconType === 'map') {
    return <Map />;
  }

  if (iconType === 'menu') {
    return <Menu />;
  }

  if (iconType === 'money') {
    return <Money />;
  }

  if (iconType === 'localshipping') {
    return <LocalShipping />;
  }

  if (iconType === 'pin') {
    return <Pin />;
  }

  if (iconType === 'security') {
    return <Security />;
  }

  if (iconType === 'send') {
    return <Send />;
  }

  if (iconType === 'subtract') {
    return <Subtract />;
  }

  if (iconType === 'square') {
    return <Square />;
  }

  if (iconType === 'timeline') {
    return <Timeline />;
  }

  if (iconType === 'trips') {
    return <Trips />;
  }

  if (iconType === 'today') {
    return <Today />;
  }

  if (iconType === 'waves') {
    return <Waves />;
  }

  if (iconType === 'widgets') {
    return <Widgets />;
  }

  if (iconType === 'facebook') {
    return <Facebook />;
  }

  if (iconType === 'google') {
    return <Google />;
  }

  if (iconType === 'business') {
    return <Business />;
  }

  if (iconType === 'arrow_forward') {
    return <ArrowForward />;
  }

  if (iconType === 'directions') {
    return <Directions />;
  }

  if (iconType === 'timelapse') {
    return <Timelapse />;
  }

  if (iconType === 'storemalldirectory') {
    return <StoreMallDirectory />;
  }

  if (iconType === 'time') {
    return <Time />;
  }

  if (iconType === 'gesture') {
    return <Gesture />;
  }

  if (iconType === 'emojipeople') {
    return <EmojiPeople />;
  }

  if (iconType === 'message') {
    return <Message />;
  }

  if (iconType === 'doneall') {
    return <DoneAll />;
  }

  if (iconType === 'star') {
    return <Star />;
  }

  if (iconType === 'factcheck') {
    return <Factcheck />;
  }

  if (iconType === 'cancelschedulesend') {
    return <CancelScheduleSend />;
  }

  if (iconType === 'thumbdown') {
    return <ThumbDown />;
  }

  if (iconType === 'removecircle') {
    return <RemoveCircle />;
  }

  return null;
};
