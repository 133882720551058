export const CONDITIONS = ['Sí', 'No'];

export const FUEL_TYPE = ['magna', 'premium', 'diesel'];

export const BOX_TYPE = [
  'Plataforma',
  'Seca (abierta)',
  'Seca (cerrada)',
  'Volcadora',
  'Refrigerada',
  'Grúa',
  'Ganadera',
  'Cama baja',
  'Madrina',
];
