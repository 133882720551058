import { addDays } from 'date-fns';

export const timestampUnix = (date: string, status: string) => {
  if (!date) return;

  let newDate = '';

  if (status === 'start') {
    newDate = `${date}T05:00:00.000Z`;
  }

  if (status === 'end') {
    newDate = addDays(new Date(`${date}T05:00:00.000Z`), 1).toISOString();
  }

  return Math.round(+new Date(newDate) / 1000);
};
