import React, { useState, useEffect } from 'react';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import algoliasearch from 'algoliasearch/lite';
import { InstantSearch, Configure } from 'react-instantsearch-dom';

import {
  RequestCard,
  Wrapper,
  Text,
  InfiniteHitsWrapper,
  Status,
} from './styles';
import { capitalizeFirstLetter } from 'utils';
import { FREIGHTS_STATUS } from './constants';
import { Icon } from 'components/Icon';

const searchClient = algoliasearch(
  process.env.REACT_APP_APPLICATION_ID || '',
  process.env.REACT_APP_API_KEY || ''
);
const index = searchClient.initIndex(
  process.env.REACT_APP_INDEX_FREIGHTS || ''
);

export const Travel = () => {
  const [amountItems, setAmountItems] = useState(0);
  const [filterValues, setFilterValues] = useState({
    id: '',
    status: 'ALL_STATUS',
  });

  useEffect(() => {
    const getAmountItems = async () => {
      const query = await index.search('');

      setAmountItems(query.nbHits);
    };

    getAmountItems();
  }, []);

  const handleFilterValues = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    setFilterValues(prevValues => ({
      ...prevValues,
      [event.target.name]: event.target.value,
    }));
  };

  return (
    <InstantSearch
      indexName={process.env.REACT_APP_INDEX_FREIGHTS || ''}
      searchClient={searchClient}
    >
      <Configure
        filters={
          filterValues.status !== 'ALL_STATUS'
            ? `status:${filterValues.status}`
            : filterValues.id
            ? `objectID:${filterValues.id}`
            : ''
        }
        hitsPerPage={20}
        analytics={false}
        distinct
      />
      <Wrapper>
        <Text>
          <p>Cargas</p> <hr /> <span>{amountItems}</span>
          <input
            type="text"
            name="id"
            placeholder="El id tiene que ser exacto"
            value={filterValues.id}
            onChange={handleFilterValues}
          />
          <select
            name="status"
            onChange={handleFilterValues}
            value={filterValues.status}
          >
            {FREIGHTS_STATUS.map((status, index) => (
              <option key={index} value={status.status}>
                {status.text}
              </option>
            ))}
          </select>
        </Text>
        <InfiniteHitsWrapper
          hitComponent={(props: any) => (
            <RequestCard
              className="link"
              to={`/travel/${props.hit.objectID}`}
              key={props.hit.objectID}
            >
              <div className="icon">
                <div className="icon-content">
                  <Icon iconType="directions" />
                </div>
                {props.hit.type === 'ENTERPRISE' && (
                  <span className="icon-text">Enterprise</span>
                )}
                {props.hit.createdAt === props.hit.updatedAt && (
                  <span className="icon-text">Nuevo</span>
                )}
              </div>
              <Status
                color={
                  FREIGHTS_STATUS.find(
                    option => option.status === props.hit.status
                  )?.color
                }
                backgroundColor={
                  FREIGHTS_STATUS.find(
                    option => option.status === props.hit.status
                  )?.backgroundColor
                }
              >
                <Icon
                  iconType={
                    FREIGHTS_STATUS.find(
                      option => option.status === props.hit.status
                    )?.icon
                  }
                />{' '}
                {
                  FREIGHTS_STATUS.find(
                    option => option.status === props.hit.status
                  )?.text
                }
              </Status>
              <div className="information">
                <p className="title">
                  {`${capitalizeFirstLetter(
                    props.hit.origin.city
                  )} - ${capitalizeFirstLetter(props.hit.destination.city)}`}
                </p>
                <p className="date">
                  {format(
                    new Date(props.hit.time),
                    'dd MMMM yyyy · hh:mm aaaa',
                    {
                      locale: es,
                    }
                  )}
                </p>
              </div>
              <p className="price">
                {props.hit.totalPrice
                  ? `$${props.hit.totalPrice.toLocaleString()}`
                  : 'Sin precio'}
              </p>
            </RequestCard>
          )}
        />
      </Wrapper>
    </InstantSearch>
  );
};
