export const GetCost = `
  query GetCost($id: ID!) {
    getCost(id: $id) {
      boxType
      boxWidth
      boxHeight
      boxLength
      axles
      fuelType
      fuelEfficiency
      measuresPhoto
      utilities {
        plan
        percentage
        discount
        insuranceDiscount
        maneuversPrice
      }
    }
  }
`;

export const CreateCost = `
  mutation CreateCost($input: CreateCostInput!) {
    createCost(input: $input) {
      id
    }
  }
`;

export const UpdateCost = `
  mutation UpdateCost($input: UpdateCostInput!) {
    updateCost(input: $input) {
      id
    }
  }
`;
