/* eslint-disable max-len */
import React from 'react';

export const FacebookCircle = () => (
  <svg id="Facebook" xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23">
    <path id="Path_16940" data-name="Path 16940" d="M291.149,508.668q-.146.024-.294.044Q291,508.692,291.149,508.668Zm0,0" transform="translate(-277.792 -485.82)" />
    <path id="Path_16941" data-name="Path 16941" d="M299.479,507.793l-.14.024Zm0,0" transform="translate(-285.894 -484.985)" />
    <path id="Path_16942" data-name="Path 16942" d="M277.954,510.34q-.171.019-.344.034Q277.782,510.359,277.954,510.34Zm0,0" transform="translate(-265.142 -487.416)" />
    <path id="Path_16943" data-name="Path 16943" d="M286.611,509.738l-.165.021Zm0,0" transform="translate(-273.58 -486.841)" />
    <path id="Path_16944" data-name="Path 16944" d="M306.787,506.41l-.124.026Zm0,0" transform="translate(-292.889 -483.666)" />
    <path id="Path_16945" data-name="Path 16945" d="M324.584,502.113l-.1.028Zm0,0" transform="translate(-309.909 -479.565)" />
    <path id="Path_16946" data-name="Path 16946" d="M319.168,503.543l-.109.028Zm0,0" transform="translate(-304.727 -480.93)" />
    <path id="Path_16947" data-name="Path 16947" d="M312.182,505.254l-.115.026Zm0,0" transform="translate(-298.049 -482.562)" />
    <path id="Path_16948" data-name="Path 16948" d="M273.665,511.09l-.185.014Zm0,0" transform="translate(-261.197 -488.132)" />
    <path id="Path_16949" data-name="Path 16949" d="M23,11.5A11.5,11.5,0,1,0,11.5,23l.2,0V14.045H9.231V11.166H11.7V9.047a3.459,3.459,0,0,1,3.692-3.795,20.338,20.338,0,0,1,2.215.113V7.933H16.1c-1.193,0-1.423.567-1.423,1.4v1.834h2.851l-.372,2.879h-2.48v8.511A11.506,11.506,0,0,0,23,11.5Zm0,0" />
    <path id="Path_16950" data-name="Path 16950" d="M265.257,511.43q-.183.012-.367.018Q265.075,511.442,265.257,511.43Zm0,0" transform="translate(-252.995 -488.456)" />
    <path id="Path_16951" data-name="Path 16951" d="M260.7,511.836l-.194,0Zm0,0" transform="translate(-248.803 -488.844)" />
  </svg>
);
