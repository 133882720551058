interface IDocument {
  type: string;
  document: string;
}

export const removeDuplicates = (
  initialArray: IDocument[],
  dbArray: IDocument[]
) => {
  if (dbArray.length === 0) return initialArray;

  const response = [];

  for (const initial of initialArray) {
    for (const db of dbArray) {
      if (initial.type === db.type) {
        if (db.document !== '') {
          response.push(db);
          break;
        } else {
          response.push(initial);
          break;
        }
      }
    }
  }

  return response;
};
