export const GetFreight = /* GraphQL */ `
  query GetFreight($id: ID!) {
    getFreight(id: $id) {
      type
      documentation {
        type
        document
      }
      origin {
        id
        city
        state
        latitude
        longitude
        address
        street
        zipCode
        suburb
        personCharge {
          name
          phoneNumber
        }
      }
      destination {
        id
        city
        state
        latitude
        longitude
        address
        street
        zipCode
        suburb
        personCharge {
          name
          phoneNumber
        }
      }
      gps {
        name
        url
        username
        password
      }
      totalPrice
      subTotalPrice
    }
  }
`;

export const UpdateLocation = /* GraphQL */ `
  mutation UpdateLocation($input: UpdateLocationInput!) {
    updateLocation(input: $input) {
      id
    }
  }
`;
