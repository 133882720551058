export const GetFreightDriver = `
  query GetFreightDriver($id: ID!, $nextToken: String) {
    getFreightDriver(id: $id) {
      id
      firstName
      lastName
      companyName
      email
      phoneNumber
      cities
      state
      businessName
      RFC
      companyAddress
      identificationDocumentPhoto
      companyPicture
      bankName
      bankAccountNumber
      interbankCode
      createdAt
      trucks(nextToken: $nextToken) {
        items {
          id
          brand
          model
          yearModel
          status
        }
        nextToken
      }
    }
  }
`;
