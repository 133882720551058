import React, { FunctionComponent } from 'react';

import { Svg, OutlinedButton, PrimaryButton, Text } from './styles';
import { Icon } from '../Icon';

export interface Props {
  className?: string;
  id: string;
  color?: string;
  iconType?: string;
  isDisabled?: boolean;
  isFull?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  text: string;
  textColor: string;
  type?: 'button' | 'submit' | 'reset' | undefined;
  variant?: string;
}

export const Button: FunctionComponent<Props> = props => {
  const {
    color,
    iconType,
    isDisabled = false,
    isFull = false,
    onClick,
    text,
    textColor,
    type = 'button',
    variant,
    className,
    id,
  } = props;

  if (variant === 'outlined') {
    return (
      <OutlinedButton
        id={id}
        className={className}
        isFull={isFull}
        color={color}
        disabled={isDisabled}
        onClick={onClick}
        type={type}
      >
        {iconType && isFull ? (
          <Svg textColor={textColor}>
            <Icon iconType={iconType} />
          </Svg>
        ) : null}
        <Text textColor={textColor}>{text}</Text>
      </OutlinedButton>
    );
  }

  return (
    <PrimaryButton
      id={id}
      className={className}
      isFull={isFull}
      color={color}
      isDisabled={isDisabled}
      disabled={isDisabled}
      onClick={onClick}
      type={type}
    >
      {iconType && isFull ? (
        <Svg textColor={textColor}>
          <Icon iconType={iconType} />
        </Svg>
      ) : null}
      <Text textColor={textColor}>{text}</Text>
    </PrimaryButton>
  );
};
