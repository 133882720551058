const isValidEmail = (email: string) => {
  const emailValidation = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1, 3}\.[0-9]{1, 3}\.[0-9]{1, 3}\.[0-9]{1, 3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return emailValidation.test(String(email).toLowerCase());
};

const isPasswordSecure = (password: string) => {
  const passwordValidation = /(?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/;

  return passwordValidation.test(String(password));
};

const arePasswordsMatching = (password: string, confirmPassword: string) =>
  password === confirmPassword;

export const validations = {
  isValidEmail,
  isPasswordSecure,
  arePasswordsMatching,
};
