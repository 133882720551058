import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Amplify from 'aws-amplify';

import { PrivateRoute } from 'hoc/PrivateRoute';
import { Header } from 'components/Header';
import { SignIn } from 'components/views/SignIn';
import { FreightDrivers } from 'components/views/FreightDrivers';
import { Travel } from 'components/views/Travel';
import { FreightDriver } from 'components/views/FreightDriver';
import { FreightDriverTruck } from 'components/views/FreightDriverTruck';
import { FreightDriverTruckEdit } from 'components/views/FreightDriverTruckEdit';
import { TravelDetail } from 'components/views/TravelDetail';
import { TravelDetailEdit } from 'components/views/TravelDetailEdit';
import { Trucks } from 'components/views/Trucks';
import { Cost } from 'components/views/Cost';
import { CostDetail } from 'components/views/CostDetail';
import { CostDetailCreateOrEdit } from 'components/views/CostDetailCreateOrEdit';
import { Profile } from 'components/views/Profile';
import { Users } from 'components/views/Users';
import { UserDetail } from 'components/views/UserDetail';

const region = 'us-east-2';

const dev = {
  aws_project_region: region,
  aws_cognito_identity_pool_id:
    'us-east-2:c32dd2f4-dd50-44b2-8f76-2060d1ceff04',
  aws_cognito_region: region,
  aws_user_pools_id: 'us-east-2_8kSprInCF',
  aws_user_pools_web_client_id: '3kq9541tns73gi7u4nleasreoe',
  oauth: {},
  aws_appsync_graphqlEndpoint:
    'https://qgtxywvj2zachnt4tdde4rq6lu.appsync-api.us-east-2.amazonaws.com/graphql',
  aws_appsync_region: region,
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  aws_user_files_s3_bucket: 'logi024402073ecb4375a47b7547a9618e4c73800-develop',
  aws_user_files_s3_bucket_region: region,
};

const prod = {
  aws_project_region: region,
  aws_cognito_identity_pool_id:
    'us-east-2:c2d64976-9c23-4323-a589-b479dde0f2cb',
  aws_cognito_region: region,
  aws_user_pools_id: 'us-east-2_bupp4GH8n',
  aws_user_pools_web_client_id: '2uv7l6se287ep0oker6rjlfdjp',
  oauth: {},
  aws_appsync_graphqlEndpoint:
    'https://yu5lbjjtareyjib7se5spn4o74.appsync-api.us-east-2.amazonaws.com/graphql',
  aws_appsync_region: region,
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  aws_user_files_s3_bucket: 'logi024402073ecb4375a47b7547a9618e4c221721-master',
  aws_user_files_s3_bucket_region: region,
};

Amplify.configure({
  ...(process.env.NODE_ENV === 'production' ? prod : dev),
});

function App() {
  return (
    <BrowserRouter>
      <Header />
      <Switch>
        <Route exact path="/" render={() => <SignIn />} />
        <PrivateRoute exact path="/freights" component={FreightDrivers} />
        <PrivateRoute exact path="/freights/:id" component={FreightDriver} />
        <PrivateRoute exact path="/travel" component={Travel} />
        <PrivateRoute exact path="/travel/:id" component={TravelDetail} />
        <PrivateRoute
          exact
          path="/travel/:id/edit"
          component={TravelDetailEdit}
        />
        <PrivateRoute exact path="/cost" component={Cost} />
        <PrivateRoute exact path="/cost/:costId" component={CostDetail} />
        <PrivateRoute
          exact
          path="/cost/create-or-edit/:costId"
          component={CostDetailCreateOrEdit}
        />
        <PrivateRoute exact path="/trucks" component={Trucks} />
        <PrivateRoute
          exact
          path="/trucks/:truckId"
          component={FreightDriverTruck}
        />
        <PrivateRoute
          exact
          path="/trucks/:truckId/edit"
          component={FreightDriverTruckEdit}
        />
        <PrivateRoute exact path="/users" component={Users} />
        <PrivateRoute exact path="/users/:id" component={UserDetail} />
        <PrivateRoute exact path="/user" component={Profile} />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
