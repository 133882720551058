import React, { FunctionComponent } from 'react';

interface Props {
  name: string;
  value?: string | number;
  isRequired?: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const Input: FunctionComponent<Props> = props => {
  const { name, value, isRequired = false, onChange } = props;

  return (
    <input
      className="item-value"
      name={name}
      value={value}
      required={isRequired}
      autoComplete="off"
      autoCorrect="off"
      spellCheck="false"
      onChange={onChange}
      onKeyPress={event => {
        if (event.key === 'Enter') {
          event.preventDefault();
        }
      }}
    />
  );
};
