export const TRUCK_STATUS = [
  {
    status: 'ALL_STATUS',
    text: 'Todos',
  },
  {
    status: 'TRUCK_IN_REVIEW',
    color: 'primary',
    text: 'En revisión',
    icon: 'hourglass',
  },
  {
    status: 'TRUCK_ACCEPTED',
    color: 'chateauGreen',
    text: 'Aceptado y disponible',
    icon: 'doneall',
  },
  {
    status: 'TRUCK_REJECTED',
    color: 'valencia',
    text: 'Rechazado',
    icon: 'thumb_down',
  },
  {
    status: 'TRUCK_INCOMPLETE_DATA',
    color: 'fireBush',
    text: 'Datos incompletos',
    icon: 'rule',
  },
];
