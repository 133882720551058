import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Auth } from 'aws-amplify';

import { validations } from 'utils';
import { INVALID_EMAIL } from 'lib/errors';
import { Button } from '../../Button';
import { Input } from '../../Input';
import { SignInContainer } from './styles';

export const SignIn = () => {
  const history = useHistory();
  const [signInData, setSignInData] = useState({
    email: '',
    password: '',
  });
  const [authErrorLabel, setAuthErrorLabel] = useState('');

  const { isValidEmail } = validations;
  const { email, password } = signInData;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { name, value },
    } = event;

    setSignInData(state => ({
      ...state,
      [name]: value,
    }));
  };

  const isValidData = () => isValidEmail(email) && password.length;

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!isValidData()) return;

    try {
      const user = await Auth.signIn(email, password);

      if (
        user &&
        user?.signInUserSession?.accessToken?.payload['cognito:groups'][0] ===
          'Admin'
      ) {
        history.push('/freights');
      }
    } catch (error) {
      setAuthErrorLabel('Email o contraseña incorrectos');
    }
  };

  return (
    <SignInContainer>
      <div className="center-card">
        <h1 className="title">Iniciar sesión</h1>
        <form className="signin-form" onSubmit={handleSubmit}>
          <Input
            name="email"
            type="email"
            label="Correo electrónico"
            placeholder="Correo electrónico"
            value={email}
            handleChange={handleChange}
            hasError={email.length > 0 && !isValidEmail(email)}
            errorMessage={INVALID_EMAIL}
          />
          <Input
            name="password"
            type="password"
            label="Contraseña"
            placeholder="Contraseña"
            value={password}
            handleChange={handleChange}
          />
          {authErrorLabel && <p className="error-message">{authErrorLabel}</p>}
          <Button
            id="submit-button"
            type="submit"
            text="CONTINUAR"
            isFull
            textColor="white"
            isDisabled={!isValidData()}
          />
        </form>
      </div>
    </SignInContainer>
  );
};
