import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: ${theme.colors.lightPrimary};
    min-height: 100vh;
    margin-top: 100px;

    .section {
      margin-top: 30px;
      width: 973px;
      padding: 0;

      &:last-child {
        margin-bottom: 90px;
      }

      .section-name {
        font-family: 'Gordita-Bold';
        font-size: ${theme.fonts.sm};
        color: ${theme.colors.secondary};
        margin-bottom: 20px;
        margin-left: 0;
      }

      .first-card {
        position: relative;
        display: grid;
        grid-template-columns: 47px 1fr auto auto;
        grid-template-rows: 36px;
        grid-template-areas: 'icon text complete status';
        align-items: center;
        padding: 34px 47px;
        height: auto;
        width: 100%;
        border: 1px solid ${theme.colors.gallery};
        border-radius: 10px;
        background-color: ${theme.colors.white};

        .icon {
          grid-area: 'icon';
          width: 32px;
          height: 32px;
          margin: 0;
          background-color: ${theme.colors.lightPrimary};
          border-radius: 9999px;
          display: flex;
          justify-content: center;
          align-items: center;

          svg {
            height: 20px;
            width: 20px;
            fill: ${theme.colors.primary};
          }
        }

        .text {
          grid-area: text;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          width: 100%;
          height: 100%;
          margin: 0;
          font-family: 'Gordita-Bold';
          font-size: ${theme.fonts.sm};
          color: ${theme.colors.secondary};

          span {
            display: inline;
          }
        }

        .edit-truck-button {
          display: flex;
          grid-area: complete;
          justify-content: center;
          align-items: center;
          width: max-content;
          height: 32px;
          border-radius: 9999px;
          border: 1px solid ${theme.colors.gallery};
          background-color: ${theme.colors.white};
          box-shadow: none;
          padding: 8px 10px;
          margin-right: 10px;
          cursor: pointer;

          span {
            font-family: 'Gordita-Medium';
            font-size: ${theme.fonts.xxs};
            color: ${theme.colors.shuttleGray};
            text-transform: uppercase;
          }

          svg {
            fill: ${theme.colors.white};
          }
        }

        .dots-content {
          position: absolute;
          right: 10px;
          top: 10px;

          .dots {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            grid-gap: 2px;
            cursor: pointer;
            width: 20px;
            height: 20px;

            div {
              background-color: ${theme.colors.secondary};
              width: 3px;
              height: 3px;
              border-radius: 9999px;
            }
          }

          .dots-buttons {
            width: 250px;
            position: absolute;
            top: 25px;
            right: 10px;
            background-color: white;
            border-radius: 10px;

            .dots-button {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 100%;
              height: 50px;
              cursor: pointer;
              font-size: 18px;
              color: ${theme.colors.secondary};
              border: 1px solid ${theme.colors.gallery};
            }
          }
        }
      }

      .pictures {
        display: grid;
        grid-template-columns: repeat(4, 286px);
        grid-gap: 10px;
        width: 100%;
        overflow-x: initial;

        &.truck {
          grid-template-columns: repeat(4, 1fr);
        }

        &.documentation {
          grid-template-columns: repeat(3, 1fr);
        }

        img {
          width: 100%;
          height: 165px;
          border-radius: 10px;
          object-fit: cover;
        }
      }

      .box {
        width: 100%;
        margin: 0 auto;
        padding: 20px;
        background-color: ${theme.colors.white};
        border: 1px solid ${theme.colors.gallery};
        border-radius: 10px;

        .box-items {
          margin: 0;
          padding: 0;
          list-style: none;

          .item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            min-height: 60px;
            border-bottom: 1px solid ${theme.colors.gallery};

            &:last-child {
              border: none;
            }

            .item-name {
              margin: 0;
              font-family: 'Gordita-Medium';
              font-size: ${theme.fonts.xxs};
              color: ${theme.colors.secondary};
            }

            .item-value {
              margin: 0;
              font-family: 'Gordita-Bold';
              font-size: ${theme.fonts.xs};
              color: ${theme.colors.secondary};
              width: 500px;
              text-align: right;

              img {
                margin: 10px;
                width: 400px;
                height: 200px;
              }
            }

            input.item-value,
            select.item-value {
              width: 250px;
              height: 30px;
              text-align: initial;
            }
          }
        }
      }
    }

    .save-button {
      background-color: ${theme.colors.primary};
      color: ${theme.colors.white};
      outline: none;
      border: none;
      border-radius: 20px;
      height: 30px;
      width: 250px;
      font-weight: bold;
      margin: 30px;
      cursor: pointer;

      &:disabled {
        opacity: 0.7;
        cursor: not-allowed;
      }
    }
  `}
`;

export const Status = styled.div`
  ${({ theme, color }) => css`
    grid-area: status;
    justify-self: end;
    display: flex;
    justify-content: center;
    align-items: center;
    width: max-content;
    height: 32px;
    padding: 8px 10px;
    border-radius: 9999px;
    background-color: ${color && theme.colors[color]}1a;

    span {
      font-family: 'Gordita-Medium';
      font-size: ${theme.fonts.xxs};
      color: ${color && theme.colors[color]};
    }

    svg {
      height: 20px;
      width: 20px;
      margin-right: 10px;
      fill: ${color && theme.colors[color]};
    }
  `}
`;

export const UploadPhoto = styled.div<{ image: string; withColor?: boolean }>`
  ${({ theme, image, withColor }) => css`
    input {
      display: none;
    }

    background-image: url('${image}');
    background-position: center;
    background-size: cover;
    background-color: ${withColor ? theme.colors.primary : theme.colors.white};
    height: 200px;
    width: ${withColor && '300px'};
    border-radius: 20px;

    label {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      cursor: pointer;

      svg {
        width: 30px;
        height: 30px;
        fill: ${theme.colors.white};
      }
    }
  `}
`;
