import { DefaultTheme } from 'styled-components';

import { media } from './media';

export const theme: DefaultTheme = {
  colors: {
    blue: '#1476EF',
    mercury: '#E5E5E5',
    bright: '#37D2B0',
    error: '#F3335C',
    lightGrayishCyan: '#edfbfa',
    lightOrange: '#FFF1EC',
    lightPrimary: '#F3F6FF',
    lightShadow: '#00000026',
    lightFireBush: '#FDF4EA',
    lightChateauGreen: '#EBF6EE',
    lightValencia: '#FDECEB',
    shadow: '#00000033',
    neutral: '#5e6b79',
    orange: '#FA7649',
    primary: '#1A5BFA',
    secondary: '#1A2C40',
    white: 'white',
    gallery: '#EEEEEE',
    chateauGreen: '#3AA757',
    fireBush: '#E89634',
    valencia: '#D7322D',
  },
  fonts: {
    lg: '24px',
    mdbase: '22px',
    md: '20px',
    sm: '16px',
    xl: '48px',
    xs: '14px',
    xxs: '12px',
    base: '18px',
  },
  media,
};
