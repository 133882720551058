import React, { useState, FunctionComponent } from 'react';

import { ErrorMessage, InputContainer, StyledInput } from './styles';

export type Status = 'focus' | 'regular' | undefined;

export interface Props {
  errorMessage?: string;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  hasError?: boolean;
  label: string;
  name: string;
  placeholder: string;
  type: string;
  value: string;
}

export const Input: FunctionComponent<Props> = props => {
  const {
    errorMessage,
    handleChange,
    hasError = false,
    label,
    name,
    placeholder,
    type = 'text',
    value,
  } = props;

  const [status, setStatus] = useState<Status>('regular');
  const inputId = `input-${name}`;
  const isFocused = status === 'focus';
  const showLabel = isFocused || hasError;

  const handleOnFocus = () => setStatus('focus');

  const handleOnBlur = () => setStatus('regular');

  return (
    <>
      <InputContainer
        hasError={hasError}
        onBlur={handleOnBlur}
        onFocus={handleOnFocus}
        status={status}
      >
        {showLabel && <label htmlFor={inputId}>{label}</label>}
        <StyledInput
          aria-label={inputId}
          hasError={hasError}
          id={inputId}
          name={name}
          onChange={handleChange}
          placeholder={isFocused ? '' : placeholder}
          status={status}
          type={type}
          value={value}
        />
      </InputContainer>
      {hasError && <ErrorMessage role="alert">{errorMessage}</ErrorMessage>}
    </>
  );
};
