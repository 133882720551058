export const CONDITIONS = ['Sí', 'No'];

export const FUEL_TYPE = ['magna', 'premium', 'diesel'];

export const BOX_TYPE = ['Plataforma', 'Seca', 'Refrigerada'];

export const PLAN_TITLE = {
  basic: 'Económico',
  normal: 'Recomendado',
  shared: 'Viaje compartido',
  prime: 'Prime',
};
