import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
import algoliasearch from 'algoliasearch/lite';
import { InstantSearch, Configure } from 'react-instantsearch-dom';

import { Icon } from 'components/Icon';
import { formatDate } from 'lib/date';
import { timestampUnix } from 'utils';
import {
  Wrapper,
  Text,
  Item,
  InfiniteHitsWrapper,
} from '../FreightDrivers/styles';

const searchClient = algoliasearch(
  process.env.REACT_APP_APPLICATION_ID || '',
  process.env.REACT_APP_API_KEY || ''
);
const index = searchClient.initIndex(process.env.REACT_APP_INDEX_COST || '');

const BOX_TYPE = ['TODOS', 'Plataforma', 'Seca', 'Refrigerada'];

export const Cost = () => {
  const router = useHistory();
  const [amountItems, setAmountItems] = useState(0);
  const [filterValues, setFilterValues] = useState({
    date: '',
    boxType: 'TODOS',
    boxLength: '',
  });

  useEffect(() => {
    const getAmountItems = async () => {
      const query = await index.search('');

      setAmountItems(query.nbHits);
    };

    getAmountItems();
  }, []);

  const handleFilterValues = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    let { name, value } = event.target;

    setFilterValues(prevValues => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleFilter = () => {
    let filter = '';

    if (filterValues.date) {
      const facet = `createdAt_timestamp:${timestampUnix(
        filterValues.date,
        'start'
      )} TO ${timestampUnix(filterValues.date, 'end')}`;

      filter = facet + filter;
    }

    if (filterValues.boxType) {
      const facet = `boxType:'${filterValues.boxType}'`;

      if (filterValues.boxType === 'TODOS') {
        filter = filter.replace(facet, '');
      } else if (filter.length > 0) {
        filter = filter + ` AND ${facet}`;
      } else {
        filter = facet;
      }
    }

    if (filterValues.boxLength) {
      const facet = `boxLength:${filterValues.boxLength}`;

      if (filter.length > 0) {
        filter = filter + ` AND ${facet}`;
      } else {
        filter = facet;
      }
    }

    return filter;
  };

  return (
    <InstantSearch
      indexName={process.env.REACT_APP_INDEX_COST || ''}
      searchClient={searchClient}
    >
      <Configure
        filters={handleFilter()}
        hitsPerPage={20}
        analytics={false}
        distinct
      />
      <Wrapper>
        <Text>
          <p>Costos</p> <hr /> <span>{amountItems}</span>
          <input
            type="date"
            name="date"
            value={filterValues.date}
            onChange={handleFilterValues}
          />
          <select
            name="boxType"
            value={filterValues.boxType}
            onChange={handleFilterValues}
          >
            {BOX_TYPE.map((type, index) => (
              <option key={index} value={type}>
                {type}
              </option>
            ))}
          </select>
          <input
            name="boxLength"
            placeholder="Largo de caja"
            value={filterValues.boxLength}
            onChange={handleFilterValues}
          />
          <button
            type="button"
            onClick={() => router.push('/cost/create-or-edit/0')}
          >
            Añadir nuevo
          </button>
        </Text>
        <InfiniteHitsWrapper
          hitComponent={(props: any) => (
            <Link
              className="link"
              to={`/cost/${props.hit.objectID}`}
              key={props.hit.objectID}
            >
              <Item>
                <div className="box-title">
                  <div className="box">
                    <div className="business">
                      <Icon iconType="business" />
                    </div>
                  </div>
                  <div className="title">
                    <p>{props.hit.boxType}</p> <hr />
                    <p>{props.hit.boxLength} metros</p> <hr />
                    <span>{formatDate(props.hit.createdAt)}</span>
                  </div>
                </div>
                <div className="box-content">
                  <div className="arrow">
                    <Icon iconType="arrow_forward" />
                  </div>
                </div>
              </Item>
            </Link>
          )}
        />
      </Wrapper>
    </InstantSearch>
  );
};
