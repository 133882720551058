import { InfiniteHits } from 'react-instantsearch-dom';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

interface StatusProps {
  color?: string;
  backgroundColor?: string;
}

export const InfiniteHitsWrapper = styled(InfiniteHits)`
  display: grid;

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  & > ul > li {
    margin-bottom: 10px;
  }
`;

export const Wrapper = styled.div`
  margin: 0 auto;
  margin-top: 100px;
  max-width: 1161px;
  width: 100%;

  .link {
    text-decoration: none;
  }

  button {
    margin: 0 auto;
  }

  .button-end {
    margin-top: 15px;
  }
`;

export const Text = styled.div`
  ${({ theme }) => css`
    padding: 50px 0;
    margin: 0;
    display: flex;
    align-items: center;
    font-size: ${theme.fonts.lg};
    color: ${theme.colors.secondary};

    p {
      font-family: 'Gordita-Bold';
      margin: 0;
    }

    hr {
      height: 20px;
      height: 20px;
      margin: 0px 10px;
      opacity: 0.7;
    }

    span {
      font-family: 'Gordita-Regular';
    }

    input,
    select {
      height: 30px;
      margin-left: 10px;
    }

    .ais-SearchBox > form > button {
      height: 30px;
      width: 30px;
      margin-left: 10px;
      background-color: ${theme.colors.white};
      outline: none;
      border: 1px solid ${theme.colors.mercury};
    }
  `}
`;

export const RequestCard = styled(Link)`
  ${({ theme }) => css`
    width: 100%;
    background-color: ${theme.colors.white};
    border-radius: 15px;
    border: 1px solid ${theme.colors.gallery};
    transition: 0.5s;
    padding: 32px 47px;
    display: grid;
    align-items: center;
    grid-template-columns: 1fr auto auto;
    grid-template-areas:
      'icon price status'
      'information price status';

    &:hover {
      box-shadow: 0px 3px 50px ${theme.colors.lightShadow};
    }

    .icon {
      display: flex;
      grid-area: icon;

      .icon-content {
        width: 32px;
        height: 32px;
        background-color: ${theme.colors.lightPrimary};
        border-radius: 9999px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 10px;

        svg {
          width: 20px;
          height: 20px;
          fill: ${theme.colors.primary};
        }
      }

      .icon-text {
        height: 32px;
        padding: 0 10px;
        margin-left: 10px;
        border-radius: 23px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: ${theme.colors.lightPrimary};
        color: ${theme.colors.primary};
        font-family: 'Gordita-Bold';
        font-size: ${theme.fonts.xs};
      }
    }

    .information {
      grid-area: information;

      & > .title {
        font-family: 'Gordita-Bold';
        color: ${theme.colors.secondary};
        line-height: 1.4;
        margin: 0;
        margin-bottom: 10px;
        font-size: ${theme.fonts.base};
      }

      .date {
        font-size: ${theme.fonts.xs};
        color: ${theme.colors.secondary};
        opacity: 0.7;
        margin: 0;
      }
    }

    .price {
      font-family: 'Gordita-Bold';
      font-size: ${theme.fonts.xs};
      background-color: ${theme.colors.lightPrimary};
      color: ${theme.colors.primary};
      padding: 10px 20px;
      margin: 0;
      margin-right: 10px;
      border-radius: 16px;
      grid-area: price;
    }
  `};
`;

export const Status = styled.div<StatusProps>`
  ${({ theme, color = 'primary', backgroundColor = 'lightPrimary' }) => css`
    width: max-content;
    height: 32px;
    border-radius: 19px;
    background-color: ${theme.colors[backgroundColor]};
    color: ${theme.colors[color]};
    padding: 0px 15px;
    font-family: 'Gordita-Medium';
    font-size: ${theme.fonts.xxs};
    display: flex;
    align-items: center;
    grid-area: status;
    justify-self: end;

    svg {
      width: 20px;
      height: 20px;
      fill: ${theme.colors[color]};
      margin-right: 10px;
    }
  `}
`;
