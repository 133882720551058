import styled, { css } from 'styled-components';

interface ButtonProps {
  readonly isFull?: boolean;
}

interface TextProps extends ButtonProps {
  readonly textColor: string;
}

interface StyledButton {
  isDisabled?: boolean;
  color?: string;
}

const Button = styled.button<ButtonProps>`
  ${({ theme, isFull }) => css`
    width: ${isFull ? '100%' : '300px'};
    height: ${isFull ? '60px' : '48px'};
    border-radius: 29px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 0;
    height: 48px;

    &:focus {
      outline: none;
    }

    ${theme.media.tablet} {
      width: ${isFull ? '100%' : '174px'};
    }

    ${theme.media.desktop} {
      height: 60px;
    }
  `}
`;

export const Text = styled.p<TextProps>`
  ${({ theme, isFull, textColor }) => css`
    margin: 0;
    font-family: 'Gordita-Bold';
    font-size: ${isFull ? theme.fonts.sm : theme.fonts.xs};
    color: ${theme.colors[textColor]};
  `}
`;

export const Svg = styled.svg.attrs<TextProps>(props => ({
  textColor: props.textColor || 'primary',
}))<TextProps>`
  ${({ theme, textColor }) => css`
    width: 22px;
    height: 22px;
    fill: ${theme.colors[textColor]};
    position: absolute;
    left: 30px;

    ${theme.media.desktop} {
      left: 20%;
    }
  `}
`;

export const PrimaryButton = styled(Button)<StyledButton>`
  ${({ theme, isDisabled, color = 'primary' }) => css`
    border: none;
    background-color: ${theme.colors[color]};
    opacity: ${isDisabled ? 0.2 : 1};
  `}
`;

export const OutlinedButton = styled(Button)<StyledButton>`
  ${({ theme, color = 'primary' }) => css`
    border: 1px solid ${theme.colors[color]};
    background-color: white;
    transition: 0.3s;

    &:hover {
      background-color: ${theme.colors[color]};

      & > * {
        color: white;
      }
    }
  `}
`;
