import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { API, Storage } from 'aws-amplify';

import { GetCost, UpdateCost, CreateCost } from '../Cost/graphql';
import { BOX_TYPE, FUEL_TYPE, PLAN_TITLE } from '../Cost/constants';
import { asConstant } from 'utils';
import { Wrapper, UploadPhoto } from '../FreightDriverTruck/styles';
import { Icon } from '../../Icon';
import { Input } from '../FreightDriverTruckEdit/input';

interface ICost {
  id?: string;
  boxType: string;
  boxWidth: string;
  boxHeight: string;
  boxLength: string;
  axles: string;
  fuelType: string;
  fuelEfficiency: string;
  measuresPhoto: string | File;
  utilities: {
    plan: 'basic' | 'normal' | 'shared' | 'prime';
    percentage: string;
    discount: string;
    insuranceDiscount: string;
    maneuversPrice: string;
  }[];
}

export const CostDetailCreateOrEdit = () => {
  const router = useHistory();
  const { costId } = useParams<{
    costId: string;
  }>();
  const [status, setStatus] = useState('idle');
  const [mutationStatus, setMutationStatus] = useState('idle');
  const [cost, setCost] = useState<ICost>({
    id: '',
    boxType: 'Plataforma',
    boxWidth: '',
    boxHeight: '',
    boxLength: '',
    axles: '',
    fuelType: 'diesel',
    fuelEfficiency: '',
    measuresPhoto: '',
    utilities: [
      {
        plan: 'basic',
        percentage: '0',
        discount: '0',
        insuranceDiscount: '0',
        maneuversPrice: '0',
      },
      {
        plan: 'normal',
        percentage: '0',
        discount: '0',
        insuranceDiscount: '0',
        maneuversPrice: '0',
      },
      {
        plan: 'shared',
        percentage: '0',
        discount: '0',
        insuranceDiscount: '0',
        maneuversPrice: '0',
      },
      {
        plan: 'prime',
        percentage: '0',
        discount: '0',
        insuranceDiscount: '0',
        maneuversPrice: '0',
      },
    ],
  });
  const [image, setImage] = useState('');
  const isLoading =
    costId !== '0' && (status === 'idle' || status === 'pending');
  const isMutationLoading = mutationStatus === 'pending';

  useEffect(() => {
    async function getQuery() {
      setStatus('pending');

      try {
        const { data } = (await API.graphql({
          query: GetCost,
          variables: {
            id: costId,
          },
        })) as any;

        if (data) {
          setCost(data.getCost);
        }

        setStatus('resolved');
      } catch {
        setStatus('rejected');
      }
    }

    if (costId !== '0') {
      getQuery();
    }
  }, [costId]);

  useEffect(() => {
    const getImage = async () => {
      if (status === 'resolved' && cost.measuresPhoto) {
        const measuresPhoto = (await Storage.get(
          cost.measuresPhoto as string
        )) as string;

        setImage(measuresPhoto);
      }
    };

    getImage();
  }, [status]);

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    event.preventDefault();

    const { name, value } = event.target;

    setCost(prevValues => {
      if (
        /(utilities).[0-9].(percentage|discount|insuranceDiscount|maneuversPrice)+$/g.test(
          name
        )
      ) {
        const [_, listIndex, listName] = name.split('.');
        const index = parseInt(listIndex, 10);

        prevValues.utilities[index] = {
          ...prevValues.utilities[index],
          [listName]: value,
        };

        return {
          ...prevValues,
          utilities: [...prevValues.utilities],
        };
      }

      return {
        ...prevValues,
        [name]: value,
      };
    });
  };

  const handleFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();

    const { name, files } = event.target;

    if (!name || !files || files?.length === 0) return;

    const file = files[0];

    if (!file.type.includes('image')) return;

    setCost(prevValues => ({ ...prevValues, [name]: file }));
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setMutationStatus('pending');

    let response;
    const measuresPhoto = (cost.measuresPhoto as File)?.name
      ? `cost/${asConstant(cost.boxType)}/${cost.boxLength}/${
          (cost.measuresPhoto as File)?.name
        }`
      : cost.measuresPhoto;

    try {
      if (costId !== '0') {
        const UpdatedCost = (await API.graphql({
          query: UpdateCost,
          variables: {
            input: {
              id: costId,
              boxType: cost.boxType,
              boxWidth: cost.boxWidth,
              boxHeight: cost.boxHeight,
              boxLength: cost.boxLength,
              axles: cost.axles,
              fuelType: cost.fuelType,
              fuelEfficiency: cost.fuelEfficiency,
              measuresPhoto: measuresPhoto,
              utilities: cost.utilities,
            },
          },
        })) as any;

        response = UpdatedCost.data.updateCost;
      } else {
        const CreatedCost = (await API.graphql({
          query: CreateCost,
          variables: {
            input: {
              boxType: cost.boxType,
              boxWidth: cost.boxWidth,
              boxHeight: cost.boxHeight,
              boxLength: cost.boxLength,
              axles: cost.axles,
              fuelType: cost.fuelType,
              fuelEfficiency: cost.fuelEfficiency,
              measuresPhoto: measuresPhoto,
              utilities: cost.utilities,
            },
          },
        })) as any;

        response = CreatedCost.data.createCost;
      }

      if ((cost.measuresPhoto as File)?.name) {
        await Storage.put(measuresPhoto as string, cost.measuresPhoto);
      }

      if (response.id) {
        router.push(`/cost/${response.id}`);
      }
    } catch (error) {
      console.error(error);

      alert('ERROR!!! Mira la consola para más información');
    }
  };

  if (isLoading) {
    return <div />;
  }

  return (
    <Wrapper>
      <form
        method="POST"
        onSubmit={isMutationLoading ? undefined : handleSubmit}
      >
        <div className="section">
          <h3 className="section-name">Datos generales</h3>
          <div className="box">
            <ul className="box-items">
              <li className="item">
                <h4 className="item-name">Tipo de caja</h4>
                <select
                  className="item-value"
                  name="boxType"
                  value={cost.boxType}
                  onChange={handleChange}
                >
                  {BOX_TYPE.map((type, index) => (
                    <option value={type} key={index}>
                      {type}
                    </option>
                  ))}
                </select>
              </li>
              <li className="item">
                <p className="item-name">Ancho de caja</p>
                <Input
                  name="boxWidth"
                  value={cost.boxWidth}
                  onChange={handleChange}
                />
              </li>
              <li className="item">
                <p className="item-name">Alto de caja</p>
                <Input
                  name="boxHeight"
                  value={cost.boxHeight}
                  onChange={handleChange}
                />
              </li>
              <li className="item">
                <p className="item-name">Largo de caja</p>
                <Input
                  name="boxLength"
                  value={cost.boxLength}
                  onChange={handleChange}
                />
              </li>
              <li className="item">
                <p className="item-name">Ejes</p>
                <Input
                  name="axles"
                  value={cost.axles}
                  onChange={handleChange}
                />
              </li>
              <li className="item">
                <h4 className="item-name">Tipo de gasolina</h4>
                <select
                  className="item-value"
                  name="fuelType"
                  value={cost.fuelType}
                  onChange={handleChange}
                >
                  {FUEL_TYPE.map((type, index) => (
                    <option value={type} key={index}>
                      {type}
                    </option>
                  ))}
                </select>
              </li>
              <li className="item">
                <p className="item-name">Rendimiento</p>
                <Input
                  name="fuelEfficiency"
                  value={cost.fuelEfficiency}
                  onChange={handleChange}
                />
              </li>
              <li className="item">
                <h4 className="item-name">Imagen</h4>
                <UploadPhoto
                  image={
                    cost.measuresPhoto instanceof File
                      ? URL.createObjectURL(cost.measuresPhoto)
                      : (image as string)
                  }
                  withColor={true}
                >
                  <input
                    type="file"
                    id="measuresPhoto"
                    accept="image/*"
                    name="measuresPhoto"
                    onChange={handleFile}
                  />
                  <label className="picture" htmlFor="measuresPhoto">
                    <Icon iconType="add" />
                  </label>
                </UploadPhoto>
              </li>
            </ul>
          </div>
        </div>
        {cost.utilities.map((utility, index) => (
          <div className="section" key={index}>
            <h3 className="section-name">{PLAN_TITLE[utility.plan]}</h3>
            <div className="box">
              <ul className="box-items">
                <li className="item">
                  <p className="item-name">Porcentaje de utilidad</p>
                  <Input
                    name={`utilities.${index}.percentage`}
                    value={cost.utilities[index].percentage}
                    onChange={handleChange}
                  />
                </li>
                <li className="item">
                  <p className="item-name">Descuento</p>
                  <Input
                    name={`utilities.${index}.discount`}
                    value={cost.utilities[index].discount}
                    onChange={handleChange}
                  />
                </li>
                <li className="item">
                  <p className="item-name">Descuento en seguro de carga</p>
                  <Input
                    name={`utilities.${index}.insuranceDiscount`}
                    value={cost.utilities[index].insuranceDiscount}
                    onChange={handleChange}
                  />
                </li>
                <li className="item">
                  <p className="item-name">Maniobras de carga</p>
                  <Input
                    name={`utilities.${index}.maneuversPrice`}
                    value={cost.utilities[index].maneuversPrice}
                    onChange={handleChange}
                  />
                </li>
              </ul>
            </div>
          </div>
        ))}
        <button
          type="submit"
          className="save-button"
          disabled={isMutationLoading}
        >
          SAVE IT NOW!
        </button>
      </form>
    </Wrapper>
  );
};
