import { InfiniteHits } from 'react-instantsearch-dom';
import styled, { css } from 'styled-components';

export const InfiniteHitsWrapper = styled(InfiniteHits)`
  width: 100%;
  text-align: center;

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
`;

export const Wrapper = styled.div`
  margin: 0 auto;
  max-width: 1161px;
  width: 100%;
  margin-top: 100px;

  .link {
    text-decoration: none;
  }

  button {
    margin: 0 auto;
  }
`;

export const Text = styled.div`
  ${({ theme }) => css`
    padding: 50px 0;
    margin: 0;
    display: flex;
    align-items: center;
    font-size: ${theme.fonts.lg};
    color: ${theme.colors.secondary};

    p {
      font-family: 'Gordita-Bold';
      margin: 0;
    }

    hr {
      height: 20px;
      height: 20px;
      margin: 0px 10px;
      opacity: 0.7;
    }

    span {
      font-family: 'Gordita-Regular';
    }

    input,
    select,
    button {
      height: 30px;
      margin-left: 10px;
    }

    .ais-SearchBox > form > button {
      height: 30px;
      width: 30px;
      margin-left: 10px;
      background-color: ${theme.colors.white};
      outline: none;
      border: 1px solid ${theme.colors.mercury};
    }
  `}
`;

export const Item = styled.div`
  ${({ theme }) => css`
    text-decoration: none;
    width: 100%;
    background-color: ${theme.colors.white};
    border-radius: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 53px;
    cursor: pointer;
    margin-bottom: 1rem;
    border: 1px solid ${theme.colors.gallery};

    .box-title {
      display: flex;
      align-items: center;

      .box {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 9999px;
        width: 33px;
        height: 33px;
        background-color: ${theme.colors.lightPrimary};
        margin-right: 1rem;

        .business {
          width: 20px;
          height: 20px;
          fill: ${theme.colors.primary};
        }
      }

      .title {
        display: flex;
        align-items: center;
        font-size: ${theme.fonts.base};
        color: ${theme.colors.neutral};

        p {
          font-family: 'Gordita-Bold';
          margin: 0;
        }

        hr {
          height: 20px;
          height: 20px;
          margin: 0px 10px;
          opacity: 0.7;
        }

        span {
          font-family: 'Gordita-Regular';
        }
      }
    }

    .box-content {
      display: flex;
      align-items: center;

      .arrow {
        width: 24px;
        height: 24px;
        fill: ${theme.colors.primary};
        margin-left: 117px;
      }

      .text {
        font-size: ${theme.fonts.xs};
        color: ${theme.colors.neutral};
      }
    }
  `}
`;
