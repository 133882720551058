import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Auth } from 'aws-amplify';

import { Wrapper } from './styles';
import { Icon } from '../../Icon';

export const Profile = () => {
  const [user, setUser] = useState('');
  const router = useHistory();

  useEffect(() => {
    const handleCurrentUser = async () => {
      const currentUser = await Auth.currentUserInfo();

      if (currentUser) {
        setUser(currentUser?.attributes?.email || '');
      }
    };

    handleCurrentUser();
  }, []);

  const signOut = () => {
    Auth.signOut().then(() => {
      router.push('/');
    });
  };

  return (
    <Wrapper>
      <div className="box">
        <img className="picture" src="/PRIVATE_SOURCE.png" alt="user" />
        <p className="name">{user}</p>
        <button type="button" className="session" onClick={signOut}>
          <Icon iconType="exit" />
          <span>Cerrar sesión</span>
        </button>
      </div>
    </Wrapper>
  );
};
