import React from 'react';

export const CancelScheduleSend = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path fill="none" d="M0 0H24V24H0z"></path>
      <path d="M16.5 9c-.42 0-.83.04-1.24.11L1.01 3 1 10l9 2-9 2 .01 7 8.07-3.46C9.59 21.19 12.71 24 16.5 24c4.14 0 7.5-3.36 7.5-7.5S20.64 9 16.5 9zm0 13c-3.03 0-5.5-2.47-5.5-5.5s2.47-5.5 5.5-5.5 5.5 2.47 5.5 5.5-2.47 5.5-5.5 5.5z"></path>
      <path d="M18.27 14.03L16.5 15.79 14.73 14.03 14.03 14.73 15.79 16.5 14.03 18.27 14.73 18.97 16.5 17.21 18.27 18.97 18.97 18.27 17.21 16.5 18.97 14.73z"></path>
    </svg>
  );
};
