import styled, { css } from 'styled-components';

export const SignInContainer = styled.div`
  ${({ theme }) => css`
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;

    .center-card {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 55px 20px;
      background-color: ${theme.colors.white};

      .title {
        width: 100%;
        margin: 0;
        margin-top: 50px;
        margin-bottom: 25px;
        color: ${theme.colors.secondary};
        display: flex;
        justify-content: center;
        font-size: ${theme.fonts.md};
        font-family: 'Gordita-Bold';
      }

      button {
        margin-bottom: 10px;

        &:last-child {
          margin-bottom: 45px;
          margin-top: 25px;
        }
      }

      .label {
        margin: 35px 0;
        display: flex;
        justify-content: center;
        font-size: ${theme.fonts.xs};
        color: ${theme.colors.secondary};
        opacity: 70%;
      }

      input {
        margin-bottom: 10px;
      }

      .error-message {
        width: 100%;
        text-align: center;
        font-size: ${theme.fonts.xs};
        color: ${theme.colors.error};
      }

      .label-signup {
        width: 100%;
        text-align: center;
        margin: 0;
        font-weight: bold;
        font-size: ${theme.fonts.sm};

        a {
          display: block;
          color: ${theme.colors.primary};
          margin-left: 5px;
        }
      }

      .terms-label {
        font-size: ${theme.fonts.xs};
        color: ${theme.colors.secondary};
        opacity: 70%;
        margin: 0;
        margin-top: 65px;
        text-align: center;
      }
    }

    ${theme.media.tablet} {
      .center-card {
        padding: 55px 50px;

        .label-signup {
          width: 100%;
          display: flex;
          justify-content: center;
          margin: 0;
          font-weight: bold;
          font-size: ${theme.fonts.sm};

          a {
            color: ${theme.colors.primary};
            margin-left: 5px;
          }
        }
      }
    }

    ${theme.media.desktop} {
      display: flex;
      justify-content: center;

      .center-card {
        display: flex;
        flex-direction: column;
        width: 785px;
        padding: 0 94px;
        padding-bottom: 100px;
        background-color: ${theme.colors.white};
        box-shadow: 0px 0.5px 6px #00000029;

        .title {
          width: 100%;
          margin: 0;
          margin-bottom: 40px;
          margin-top: 78px;
          font-size: ${theme.fonts.xl};
        }

        button {
          margin-bottom: 15px;

          &:last-child {
            margin-bottom: 0;
            margin-top: 25px;
          }
        }

        .label {
          margin: 30px 0 45px 0;
          display: flex;
          justify-content: center;
          font-size: ${theme.fonts.xs};
          color: ${theme.colors.secondary};
          opacity: 70%;
        }

        input {
          margin-bottom: 15px;
        }

        .label-signup {
          width: 100%;
          display: flex;
          justify-content: center;
          margin: 50px 0;
          font-weight: bold;
          font-size: ${theme.fonts.sm};

          a {
            color: ${theme.colors.primary};
            margin-left: 5px;
          }
        }

        .terms-label {
          font-size: ${theme.fonts.xs};
          color: ${theme.colors.secondary};
          opacity: 70%;
          margin: 0;
          margin-top: 25px;
        }
      }
    }
  `}
`;
