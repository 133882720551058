export const GetTruck = `
  query GetTruck($id: ID!) {
    getTruck(id: $id) {
      id
      status
      brand
      model
      yearModel
      fuelType
      enrolNumber
      hasGPS
      fuelEfficiency
      tons
      axles
      surplusAxles
      frontPhoto
      sidePhoto
      backPhoto
      measuresPhoto
      circulationCardPhoto
      vehicleVerificationPhoto
      insurancePolicyPhoto
      utilityPercentage
      box {
        id
        type
        width
        height
        length
      }
      aduanaDocumentation {
        type
        document
      }
      freightDriver {
        id
        firstName
        lastName
        companyName
        email
        phoneNumber
        cities
        state
        businessName
        RFC
        companyAddress
        identificationDocumentPhoto
        companyPicture
        bankName
        bankAccountNumber
        interbankCode
      }
    }
  }
`;

export const UpdateTruck = `
  mutation UpdateTruck(
    $input: UpdateTruckInput!
  ) {
    updateTruck(input: $input) {
      id
    }
  }
`;

export const UpdateBox = `
  mutation UpdateBox($input: UpdateBoxInput!) {
    updateBox(input: $input) {
      id
    }
  }
`;
