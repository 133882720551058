import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.lightPrimary};
    min-height: 100vh;
    padding-top: 100px;

    .box {
      height: 612px;
      background-color: ${theme.colors.white};
      padding: 50px 0;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      width: 785px;

      .picture {
        width: 95px;
        height: 95px;
        border-radius: 9999px;
        background-color: ${theme.colors.secondary};
      }

      .name {
        font-family: 'Gordita-Bold';
        color: ${theme.colors.secondary};
        font-size: ${theme.fonts.lg};
      }

      .session {
        display: flex;
        align-items: center;
        position: absolute;
        top: 80vh;
        opacity: 70%;
        cursor: pointer;
        background-color: ${theme.colors.white};
        border: none;
        outline: none;

        svg {
          width: 24px;
          height: 24px;
          fill: ${theme.colors.secondary};
        }

        span {
          font-size: ${theme.fonts.xs};
          color: ${theme.colors.secondary};
          margin-left: 14px;
        }
      }
    }
  `}
`;
