import styled, { css } from 'styled-components';

interface Props {
  isSignIn?: boolean;
}

export const HeaderContainer = styled.div<Props>`
  ${({ theme, isSignIn }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0;
    z-index: 20;
    background-color: ${theme.colors.white};
    height: 100px;
    width: 100%;
    padding: 0 100px;
    border-bottom: 1px solid ${theme.colors.mercury};

    ${isSignIn &&
    css`
      background-color: transparent;
      border: none;
    `};

    .menu {
      display: flex;
      align-items: center;

      .menu-logo {
        cursor: pointer;
        margin-right: 68px;

        img {
          width: 80px;
        }
      }

      a {
        text-decoration: none;
      }

      .text {
        margin-right: 35px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100px;
        position: relative;

        p {
          font-size: ${theme.fonts.sm};
          font-family: 'Gordita-Bold';
          color: ${theme.colors.secondary};
          cursor: pointer;
          margin: 0;
        }
      }

      .active {
        p {
          color: ${theme.colors.primary};
        }

        span {
          width: 108px;
          height: 2px;
          background-color: ${theme.colors.primary};
          position: absolute;
          top: 57px;
        }
      }
    }

    .user {
      width: 48px;
      height: 48px;
      border-radius: 9999px;
      cursor: pointer;
    }
  `};
`;
