import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Auth } from 'aws-amplify';

import { HeaderContainer } from './styles';

export const Header = () => {
  const location = useLocation();
  const [user, setUser] = useState({});
  const isAuthenticated = Object.entries(user).length !== 0;
  const isSignIn = location.pathname === '/';
  const isFreights = location.pathname.includes('freights');
  const isTravel = location.pathname.includes('travel');
  const isTrucks = location.pathname.includes('trucks');
  const isCost = location.pathname.includes('cost');
  const isUsers = location.pathname.includes('users');

  useEffect(() => {
    async function handleAuthentication() {
      try {
        const currentUser = await Auth.currentAuthenticatedUser();
        const isAdmin =
          currentUser?.signInUserSession?.accessToken?.payload[
            'cognito:groups'
          ][0] === 'Admin';

        if (currentUser && isAdmin) {
          setUser(currentUser);
        }
      } catch {
        setUser({});
      }
    }

    handleAuthentication();
  }, [location.pathname]);

  return (
    <HeaderContainer isSignIn={isSignIn}>
      <div className="menu">
        <div className="menu-logo">
          <img src="/logo.png" alt="Yago logo" />
        </div>
        {isAuthenticated && (
          <>
            <Link to="/freights">
              <div className={`text ${isFreights ? 'active' : ''}`}>
                <p>Transportistas</p>
                <span />
              </div>
            </Link>
            <Link to="/travel">
              <div className={`text ${isTravel ? 'active' : ''}`}>
                <p>Cargas</p>
                <span />
              </div>
            </Link>
            <Link to="/trucks">
              <div className={`text ${isTrucks ? 'active' : ''}`}>
                <p>Camiones</p>
                <span />
              </div>
            </Link>
            <Link to="/cost">
              <div className={`text ${isCost ? 'active' : ''}`}>
                <p>Costos</p>
                <span />
              </div>
            </Link>
            <Link to="/users">
              <div className={`text ${isUsers ? 'active' : ''}`}>
                <p>Usuarios</p>
                <span />
              </div>
            </Link>
          </>
        )}
      </div>
      {isAuthenticated && (
        <Link to="/user">
          <img className="user" src="/PRIVATE_SOURCE.png" alt="user-logo" />
        </Link>
      )}
    </HeaderContainer>
  );
};
