import styled, { css, keyframes } from 'styled-components';

import { Status } from '.';

interface InputStateProps {
  status?: Status;
  hasError?: boolean;
}

const getInputStatusColor = (status: Status, theme: any) => {
  const inputStatusColors = {
    focus: theme.colors.primary,
    regular: theme.colors.mercury,
  };

  if (!status) return null;

  return inputStatusColors[status];
};

const move = keyframes`
  from {
    top: 30px;
  }

  to {
    top: -8px;
  }
`;

export const ErrorMessage = styled.p`
  color: ${({ theme }) => theme.colors.error};
  font-size: ${({ theme }) => theme.fonts.xs};
  padding-left: 26px;
  margin: 0;
  margin-bottom: 10px;
`;

export const InputContainer = styled.div<InputStateProps>`
  ${({ theme, status, hasError }) => css`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: relative;
    height: 56px;

    ${theme.media.desktop} {
      height: 68px;
    }

    label {
      background-color: ${theme.colors.white};
      color: ${getInputStatusColor(status, theme)};
      font-size: ${theme.fonts.xs};
      left: 26px;
      padding: 0 10px 0 5px;
      position: absolute;
      width: fit-content;
      z-index: 3;
      animation: ${move} 0.5s;
      top: -8px;
    }

    ${hasError &&
    css`
      label {
        color: ${({ theme }) => theme.colors.error};
      }
    `}
  `}
`;

export const StyledInput = styled.input<InputStateProps>`
  ${({ theme, status = 'regular', hasError }) => css`
    width: 100%;
    border: 1px solid ${getInputStatusColor(status, theme)};
    border-radius: 30px;
    outline: none;
    padding: 0 26px;
    height: 48px;

    ${theme.media.desktop} {
      height: 60px;
    }

    ${hasError &&
    css`
      border: 1px solid ${theme.colors.error};
    `}
  `}
`;
