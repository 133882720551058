import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import algoliasearch from 'algoliasearch/lite';
import { InstantSearch, SearchBox, Configure } from 'react-instantsearch-dom';

import { Icon } from 'components/Icon';
import { formatDate } from 'lib/date';
import { timestampUnix } from 'utils';
import { Item, Wrapper, Text, InfiniteHitsWrapper } from './styles';

const searchClient = algoliasearch(
  process.env.REACT_APP_APPLICATION_ID || '',
  process.env.REACT_APP_API_KEY || ''
);
const index = searchClient.initIndex(process.env.REACT_APP_INDEX_NAME || '');

export const FreightDrivers = () => {
  const [amountItems, setAmountItems] = useState(0);
  const [filterValues, setFilterValues] = useState({
    date: '',
  });

  useEffect(() => {
    const getAmountItems = async () => {
      const query = await index.search('');

      setAmountItems(query.nbHits);
    };

    getAmountItems();
  }, []);

  const handleFilterValues = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterValues(prevValues => ({
      ...prevValues,
      [event.target.name]: event.target.value,
    }));
  };

  return (
    <InstantSearch
      indexName={process.env.REACT_APP_INDEX_NAME || ''}
      searchClient={searchClient}
    >
      <Configure
        filters={
          filterValues.date
            ? `createdAt_timestamp:${timestampUnix(
                filterValues.date,
                'start'
              )} TO ${timestampUnix(filterValues.date, 'end')}`
            : ''
        }
        hitsPerPage={20}
        analytics={false}
        distinct
      />
      <Wrapper>
        <Text>
          <p>Transportistas</p> <hr /> <span>{amountItems}</span>
          <SearchBox />
          <input
            type="date"
            name="date"
            value={filterValues.date}
            onChange={handleFilterValues}
          />
        </Text>
        <InfiniteHitsWrapper
          hitComponent={(props: any) => (
            <Link
              className="link"
              to={`/freights/${props.hit.objectID}`}
              key={props.hit.objectID}
            >
              <Item>
                <div className="box-title">
                  <div className="box">
                    <div className="business">
                      <Icon iconType="business" />
                    </div>
                  </div>
                  <div className="title">
                    <p>
                      {props.hit.firstName} {props.hit.lastName}
                    </p>
                    <hr />
                    <span>{formatDate(props.hit.createdAt)}</span>
                  </div>
                </div>
                <div className="box-content">
                  <div className="text">{props.hit.state}</div>
                  <div className="arrow">
                    <Icon iconType="arrow_forward" />
                  </div>
                </div>
              </Item>
            </Link>
          )}
        />
      </Wrapper>
    </InstantSearch>
  );
};
