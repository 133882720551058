export const GetFreight = /* GraphQL */ `
  query GetFreight($id: ID!) {
    getFreight(id: $id) {
      id
      time
      type
      paymentMethod
      travelDistance
      travelTime
      travelExpenses
      tollPrice
      fuelPrice
      merchandiseDescription
      packingDescription
      hasManeuvers
      maneuversPrice
      hasInsurance
      merchandiseValue
      insurancePrice
      insuranceDiscount
      additionalComments
      status
      plan
      utilityPercentage
      discount
      discountPrice
      freightDriverCommission
      companyCommission
      freightDriverSalary
      pricePerBoxLength
      taxesPrice
      subTotalPrice
      totalPrice
      user {
        id
        firstName
        lastName
        email
        phoneNumber
      }
      truck {
        boxType
        boxWidth
        boxHeight
        boxLength
        axles
        fuelType
        fuelEfficiency
        tons
      }
      documentation {
        type
        document
      }
      freightDriver {
        id
        companyName
      }
      origin {
        city
        state
        latitude
        longitude
        address
        street
        zipCode
        suburb
        personCharge {
          name
          phoneNumber
        }
      }
      destination {
        city
        state
        latitude
        longitude
        address
        street
        zipCode
        suburb
        personCharge {
          name
          phoneNumber
        }
      }
      gps {
        name
        url
        username
        password
      }
      createdAt
      updatedAt
    }
  }
`;

export const UpdateFreight = /* GraphQL */ `
  mutation UpdateFreight($input: UpdateFreightInput!) {
    updateFreight(input: $input) {
      id
    }
  }
`;
