import React, { useState, useEffect, FunctionComponent } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { Auth } from 'aws-amplify';

interface Props {
  exact: true;
  path: string;
  component: () => JSX.Element;
}

export const PrivateRoute: FunctionComponent<Props> = ({
  component: Component,
  ...props
}) => {
  const [isAuthenticated, setIsAthenticated] = useState(false);
  const [isLoaded, setLoaded] = useState(false);

  useEffect(() => {
    async function handleAuthentication() {
      try {
        const user = await Auth.currentUserCredentials();

        if (user.authenticated) {
          setIsAthenticated(true);
        }

        setLoaded(true);
      } catch {
        setIsAthenticated(false);
        setLoaded(true);
      }
    }

    handleAuthentication();
  }, []);

  if (!isLoaded) return null;

  return isAuthenticated ? (
    <Route {...props} render={() => <Component />} />
  ) : (
    <Redirect to="/" />
  );
};
