import React from 'react';

export const Google = () => (
  <svg
    id="LOGO_Gmail"
    data-name="LOGO Gmail"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 28 28"
  >
    <path
      id="Path_5514"
      data-name="Path 5514"
      /* eslint-disable-next-line max-len */
      d="M5.179,106.938a5.833,5.833,0,0,1,.826-3v-3.725H2.28a11.054,11.054,0,0,0,0,13.447H6v-3.725A5.833,5.833,0,0,1,5.179,106.938Z"
      transform="translate(0 -95.89)"
      fill="#fbbd00"
    />
    <path
      id="Path_5515"
      data-name="Path 5515"
      /* eslint-disable-next-line max-len */
      d="M198.589,373.783,196,376.372l2.589,2.589a10.965,10.965,0,0,0,6.723-2.28v-3.721h-3.721A5.869,5.869,0,0,1,198.589,373.783Z"
      transform="translate(-187.541 -356.865)"
      fill="#0f9d58"
    />
    <path
      id="Path_5516"
      data-name="Path 5516"
      /* eslint-disable-next-line max-len */
      d="M56.548,325.477,52.823,329.2a11.225,11.225,0,0,0,.956,1.089,10.976,10.976,0,0,0,7.812,3.236v-5.179A5.872,5.872,0,0,1,56.548,325.477Z"
      transform="translate(-50.543 -311.43)"
      fill="#31aa52"
    />
    <path
      id="Path_5517"
      data-name="Path 5517"
      /* eslint-disable-next-line max-len */
      d="M267.048,199.856a11.146,11.146,0,0,0-.181-2l-.1-.531H256V202.5h5.242A5.843,5.843,0,0,1,259,204.9l3.721,3.721a11.218,11.218,0,0,0,1.089-.956A10.976,10.976,0,0,0,267.048,199.856Z"
      transform="translate(-244.952 -188.808)"
      fill="#3c79e6"
    />
    <path
      id="Path_5518"
      data-name="Path 5518"
      /* eslint-disable-next-line max-len */
      d="M202.74,6.9l.458.458,3.662-3.662-.458-.458A10.976,10.976,0,0,0,198.589,0L196,2.589l2.589,2.589A5.831,5.831,0,0,1,202.74,6.9Z"
      transform="translate(-187.541)"
      fill="#cf2d48"
    />
    <path
      id="Path_5519"
      data-name="Path 5519"
      /* eslint-disable-next-line max-len */
      d="M61.591,5.179V0a10.976,10.976,0,0,0-7.812,3.236,11.214,11.214,0,0,0-.956,1.089L56.547,8.05a5.872,5.872,0,0,1,5.044-2.871Z"
      transform="translate(-50.542 0)"
      fill="#eb4132"
    />
  </svg>
);
