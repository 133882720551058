import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { API } from 'aws-amplify';

import { Icon } from 'components/Icon';
import { FreightDriverContent } from '../FreightDriver/styles';
import { formatDate } from 'lib/date';
import { GetUser } from './graphql';

interface IUser {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  createdAt: string;
}

export const UserDetail = () => {
  const { id } = useParams<{ id: string }>();
  const [user, setUser] = useState<IUser>({
    id: '',
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    createdAt: '',
  });

  useEffect(() => {
    const handleFreightDriver = async () => {
      const { data } = (await API.graphql({
        query: GetUser,
        variables: {
          id,
        },
      })) as any;

      setUser(data.getUser);
    };

    handleFreightDriver();
  }, [id]);

  if (!user) {
    return <div />;
  }

  return (
    <FreightDriverContent>
      <div className="header">
        <div className="content">
          <div className="logo">
            <Icon iconType="business" />
          </div>
          <div className="title">
            <p>
              {user.firstName} {user.lastName}
            </p>
            <hr />
            {user.createdAt && <span>{formatDate(user.createdAt)}</span>}
          </div>
        </div>
      </div>
      <div className="user-content">
        <p>
          Nombres: <span>{user.firstName}</span>
        </p>
        <p>
          Apellidos: <span>{user.lastName}</span>
        </p>
        <p>
          Correo Electrónico: <span>{user.email}</span>
        </p>
        <p>
          Numero de teléfono: <span>{user.phoneNumber}</span>
        </p>
      </div>
    </FreightDriverContent>
  );
};
