import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { API, Storage } from 'aws-amplify';

import { TRUCK_STATUS } from '../FreightDrivers/constants';
import { GetTruck, UpdateTruck } from './graphql';
import { Icon } from '../../Icon';
import { Wrapper, Status } from './styles';

interface IFreightDriver {
  id: string;
  firstName: string;
  lastName: string;
  companyName: string;
  email: string;
  phoneNumber: string;
  cities: string[];
  state: string;
  businessName: string;
  RFC: string;
  companyAddress: string;
  identificationDocumentPhoto: string;
  companyPicture: string;
  bankName: string;
  bankAccountNumber: string;
  interbankCode: string;
}

interface IBox {
  id: string;
  type: string;
  width: number;
  height: number;
  length: number;
}

interface IAduanaDocumentation {
  type: string;
  document: string;
}

interface ITruck {
  id: string;
  status: string;
  brand?: string;
  model?: string;
  yearModel?: string;
  fuelType?: string;
  enrolNumber?: string;
  hasGPS?: boolean;
  fuelEfficiency?: number;
  tons?: number;
  axles?: number;
  surplusAxles?: number;
  frontPhoto?: string;
  sidePhoto?: string;
  backPhoto?: string;
  measuresPhoto?: string;
  circulationCardPhoto?: string;
  vehicleVerificationPhoto?: string;
  insurancePolicyPhoto?: string;
  utilityPercentage?: number;
  aduanaDocumentation: IAduanaDocumentation[];
  box: IBox;
  freightDriver?: IFreightDriver;
}

interface IImages {
  frontPhotos?: string;
  sidePhotos?: string;
  backPhotos?: string;
  measuresPhotos?: string;
  circulationCardPhotos?: string;
  vehicleVerificationPhotos?: string;
  insurancePolicyPhotos?: string;
  aduanaDocumentation?: IAduanaDocumentation[];
  companyPicture?: string;
  identificationDocumentPhoto?: string;
}

export const FreightDriverTruck = () => {
  const router = useHistory();
  const { id: driverId, truckId } = useParams<{
    id: string;
    truckId: string;
  }>();
  const [status, setStatus] = useState('idle');
  const [truck, setTruck] = useState<ITruck>({
    id: '',
    status: '',
    brand: '',
    model: '',
    yearModel: '',
    fuelType: '',
    enrolNumber: '',
    hasGPS: false,
    fuelEfficiency: 0,
    tons: 0,
    axles: 0,
    surplusAxles: 0,
    frontPhoto: '',
    sidePhoto: '',
    backPhoto: '',
    measuresPhoto: '',
    circulationCardPhoto: '',
    vehicleVerificationPhoto: '',
    insurancePolicyPhoto: '',
    utilityPercentage: 0,
    aduanaDocumentation: [],
    box: {
      id: '',
      type: '',
      width: 0,
      height: 0,
      length: 0,
    },
  });
  const [images, setImages] = useState<IImages>({
    frontPhotos: '',
    sidePhotos: '',
    backPhotos: '',
    measuresPhotos: '',
    circulationCardPhotos: '',
    vehicleVerificationPhotos: '',
    insurancePolicyPhotos: '',
    aduanaDocumentation: [],
    companyPicture: '',
    identificationDocumentPhoto: '',
  });
  const [isDotsOptionOpen, setIsDotsOptionOpen] = useState(false);
  const isLoading = status === 'idle' || status === 'pending';

  useEffect(() => {
    async function getQuery() {
      setStatus('pending');

      try {
        const { data } = (await API.graphql({
          query: GetTruck,
          variables: {
            id: truckId,
          },
        })) as any;

        if (data) {
          setTruck(data.getTruck);
        }

        setStatus('resolved');
      } catch {
        setStatus('rejected');
      }
    }

    getQuery();
  }, [truckId]);

  useEffect(() => {
    const getImages = async () => {
      if (status === 'resolved') {
        const docImages = (await Promise.all([
          truck.frontPhoto
            ? (Storage.get(truck.frontPhoto) as Promise<string>)
            : (Promise.resolve('') as Promise<string>),
          truck.sidePhoto
            ? (Storage.get(truck.sidePhoto) as Promise<string>)
            : (Promise.resolve('') as Promise<string>),
          truck.backPhoto
            ? (Storage.get(truck.backPhoto) as Promise<string>)
            : (Promise.resolve('') as Promise<string>),
          truck.measuresPhoto
            ? (Storage.get(truck.measuresPhoto) as Promise<string>)
            : (Promise.resolve('') as Promise<string>),
          truck.circulationCardPhoto
            ? (Storage.get(truck.circulationCardPhoto) as Promise<string>)
            : (Promise.resolve('') as Promise<string>),
          truck.vehicleVerificationPhoto
            ? (Storage.get(truck.vehicleVerificationPhoto) as Promise<string>)
            : (Promise.resolve('') as Promise<string>),
          truck.insurancePolicyPhoto
            ? (Storage.get(truck.insurancePolicyPhoto) as Promise<string>)
            : (Promise.resolve('') as Promise<string>),
          truck.freightDriver?.companyPicture
            ? (Storage.get(
                truck.freightDriver?.companyPicture
              ) as Promise<string>)
            : (Promise.resolve('') as Promise<string>),
          truck.freightDriver?.identificationDocumentPhoto
            ? (Storage.get(
                truck.freightDriver?.identificationDocumentPhoto
              ) as Promise<string>)
            : (Promise.resolve('') as Promise<string>),
        ])) as string[];

        setImages(prevValues => ({
          ...prevValues,
          frontPhotos: docImages[0],
          sidePhotos: docImages[1],
          backPhotos: docImages[2],
          measuresPhotos: docImages[3],
          circulationCardPhotos: docImages[4],
          vehicleVerificationPhotos: docImages[5],
          insurancePolicyPhotos: docImages[6],
          companyPicture: docImages[7],
          identificationDocumentPhoto: docImages[8],
        }));

        if (truck.aduanaDocumentation.length > 0) {
          const aduanaDocumentation = await Promise.all(
            truck.aduanaDocumentation.map(async item => ({
              type: item.type,
              document: (await Storage.get(item.document)) as string,
            }))
          );

          setImages(prevValues => ({ ...prevValues, aduanaDocumentation }));
        }
      }
    };

    getImages();
  }, [status]);

  const handleTruckStatus = async (status: string) => {
    const id = truck?.id;

    if (!id || !status) return;

    const updateTruck = (await API.graphql({
      query: UpdateTruck,
      variables: {
        input: {
          id,
          status,
        },
      },
    })) as any;

    if (updateTruck.data.updateTruck?.id) {
      router.go(0);
    }
  };

  if (isLoading) {
    return <div />;
  }

  return (
    <Wrapper>
      <div className="section">
        <div className="first-card">
          <figure className="icon">
            <Icon iconType="localshipping" />
          </figure>
          <p className="text">
            {truck.brand}, {truck.yearModel} <span>&nbsp;·&nbsp;</span> Soporta{' '}
            {truck.tons} toneladas
          </p>
          {truck.status === 'TRUCK_IN_REVIEW' && (
            <div
              className="edit-truck-button"
              onClick={() => {
                handleTruckStatus('TRUCK_ACCEPTED');
              }}
            >
              <Icon iconType="edit" />
              <span>Aceptar camión</span>
            </div>
          )}
          {truck?.status && (
            <Status
              color={
                TRUCK_STATUS.find(option => option.status === truck.status)
                  ?.color
              }
            >
              <Icon
                iconType={
                  TRUCK_STATUS.find(option => option.status === truck.status)
                    ?.icon
                }
              />
              <span>
                {
                  TRUCK_STATUS.find(option => option.status === truck.status)
                    ?.text
                }
              </span>
            </Status>
          )}
          <div className="dots-content">
            <div
              className="dots"
              onClick={() => setIsDotsOptionOpen(!isDotsOptionOpen)}
            >
              <div />
              <div />
              <div />
            </div>
            {isDotsOptionOpen && (
              <div className="dots-buttons">
                <div
                  className="dots-button"
                  onClick={() => {
                    router.push(`/trucks/${truck.id}/edit`);
                  }}
                >
                  <span>Editar camión</span>
                </div>
                <div
                  className="dots-button"
                  onClick={() => {
                    if (
                      window.confirm('¿Esta seguro de rechazar este camión?')
                    ) {
                      handleTruckStatus('TRUCK_REJECTED');
                    }
                  }}
                >
                  <span>Rechazar camión</span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="section">
        <h3 className="section-name">Fotografías</h3>
        <div className="pictures truck">
          {images.frontPhotos && (
            <a href={images.frontPhotos}>
              <img src={images.frontPhotos} />
            </a>
          )}
          {images.sidePhotos && (
            <a href={images.sidePhotos}>
              <img src={images.sidePhotos} />
            </a>
          )}
          {images.backPhotos && (
            <a href={images.backPhotos}>
              <img src={images.backPhotos} />
            </a>
          )}
          {images.measuresPhotos && (
            <a href={images.measuresPhotos}>
              <img src={images.measuresPhotos} />
            </a>
          )}
        </div>
      </div>
      <div className="section">
        <h3 className="section-name">Datos generales</h3>
        <div className="box">
          <ul className="box-items">
            <li className="item">
              <h4 className="item-name">Marca del vehículo</h4>
              <p className="item-value">{truck.brand}</p>
            </li>
            <li className="item">
              <p className="item-name">Modelo del vehículo</p>
              <p className="item-value">{truck.model}</p>
            </li>
            <li className="item">
              <p className="item-name">Año del vehículo</p>
              <p className="item-value">{truck.yearModel}</p>
            </li>
            <li className="item">
              <p className="item-name">Número de matrícula</p>
              <p className="item-value">{truck.enrolNumber}</p>
            </li>
            <li className="item">
              <p className="item-name">GPS</p>
              <p className="item-value">
                {truck.hasGPS ? 'Sí cuenta' : 'No cuenta'}
              </p>
            </li>
          </ul>
        </div>
      </div>
      <div className="section">
        <h3 className="section-name">Carga</h3>
        <div className="box">
          <ul className="box-items">
            <li className="item">
              <p className="item-name">Tipo de combustible</p>
              <p className="item-value">{truck.fuelType}</p>
            </li>
            <li className="item">
              <p className="item-name">Rendimiento gasolina KM/Litro</p>
              <p className="item-value">{truck.fuelEfficiency}</p>
            </li>
            <li className="item">
              <p className="item-name">Toneladas que soporta</p>
              <p className="item-value">{truck.tons}</p>
            </li>
            <li className="item">
              <p className="item-name">Ejes del camión</p>
              <p className="item-value">{truck.axles}</p>
            </li>
            <li className="item">
              <p className="item-name">Ejes excedentes del camión</p>
              <p className="item-value">{truck.surplusAxles}</p>
            </li>
          </ul>
        </div>
      </div>
      <div className="section">
        <h3 className="section-name">Caja</h3>
        <div className="box">
          <ul className="box-items">
            <li className="item">
              <p className="item-name">Tipo de caja de camión</p>
              <p className="item-value">{truck.box.type}</p>
            </li>
            <li className="item">
              <p className="item-name">Ancho de la caja en metros</p>
              <p className="item-value">{truck.box.width}</p>
            </li>
            <li className="item">
              <p className="item-name">Altura de la caja en metros</p>
              <p className="item-value">{truck.box.height}</p>
            </li>
            <li className="item">
              <p className="item-name">Longitud de caja</p>
              <p className="item-value">{truck.box.length}</p>
            </li>
          </ul>
        </div>
      </div>
      <div className="section">
        <h3 className="section-name">Ganancias</h3>
        <div className="box">
          <ul className="box-items">
            <li className="item">
              <p className="item-name">Porcentaje de utilidad</p>
              <p className="item-value">{truck.utilityPercentage}%</p>
            </li>
          </ul>
        </div>
      </div>
      <div className="section">
        <h3 className="section-name">Documentación</h3>
        <div className="pictures documentation">
          {images.circulationCardPhotos && (
            <a href={images.circulationCardPhotos}>
              <img src={images.circulationCardPhotos} />
            </a>
          )}
          {images.vehicleVerificationPhotos && (
            <a href={images.vehicleVerificationPhotos}>
              <img src={images.vehicleVerificationPhotos} />
            </a>
          )}
          {images.insurancePolicyPhotos && (
            <a href={images.insurancePolicyPhotos}>
              <img src={images.insurancePolicyPhotos} />
            </a>
          )}
        </div>
      </div>
      {images?.aduanaDocumentation && images?.aduanaDocumentation.length > 0 ? (
        <>
          <div className="section">
            <h3 className="section-name">Permiso de aduanas</h3>
            <div className="box">
              <ul className="box-items">
                {images.aduanaDocumentation.map((aduana, index) => (
                  <li className="item" key={index}>
                    <p className="item-name">Aduana</p>
                    <p className="item-value">{aduana.type}</p>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="section">
            <h3 className="section-name">Documentación aduanas</h3>
            <div className="box">
              <ul className="box-items">
                {images.aduanaDocumentation.map((aduana, index) => (
                  <a href={aduana.document} className="item" key={index}>
                    <p className="item-name">{aduana.type}</p>
                    <p className="item-value">Documento</p>
                  </a>
                ))}
              </ul>
            </div>
          </div>
        </>
      ) : null}
      <div className="section">
        <h3 className="section-name">Transportista</h3>
        <div className="box">
          <ul className="box-items">
            <li className="item">
              <p className="item-name">Nombre de la compañia</p>
              <p className="item-value">{truck.freightDriver?.companyName}</p>
            </li>
            <li className="item">
              <p className="item-name">Correo Electrónico</p>
              <p className="item-value">{truck.freightDriver?.email}</p>
            </li>
            <li className="item">
              <p className="item-name">Numero de teléfono</p>
              <p className="item-value">{truck.freightDriver?.phoneNumber}</p>
            </li>
            <li className="item">
              <p className="item-name">Ubicaciones</p>
              <p className="item-value">
                {truck.freightDriver?.cities?.join(', ')} -{' '}
                {truck.freightDriver?.state}
              </p>
            </li>
            <li className="item">
              <p className="item-name">Razón social</p>
              <p className="item-value">{truck.freightDriver?.businessName}</p>
            </li>
            <li className="item">
              <p className="item-name">RFC</p>
              <p className="item-value">{truck.freightDriver?.RFC}</p>
            </li>
            <li className="item">
              <p className="item-name">Dirección de compañia</p>
              <p className="item-value">
                {truck.freightDriver?.companyAddress}
              </p>
            </li>
            <li className="item">
              <p className="item-name">Nombre de banco</p>
              <p className="item-value">{truck.freightDriver?.bankName}</p>
            </li>
            <li className="item">
              <p className="item-name">Numero de cuenta</p>
              <p className="item-value">
                {truck.freightDriver?.bankAccountNumber}
              </p>
            </li>
            <li className="item">
              <p className="item-name">Clave interbancaria</p>
              <p className="item-value">{truck.freightDriver?.interbankCode}</p>
            </li>
            {images.companyPicture && (
              <li className="item">
                <p className="item-name">Logotipo de la empresa</p>
                <p className="item-value">
                  <a href={images.companyPicture}>
                    <img src={images.companyPicture} />
                  </a>
                </p>
              </li>
            )}
            {images.identificationDocumentPhoto && (
              <li className="item">
                <p className="item-name">Foto INE/IFE/Pasaporte</p>
                <p className="item-value">
                  <a href={images.identificationDocumentPhoto}>
                    <img src={images.identificationDocumentPhoto} />
                  </a>
                </p>
              </li>
            )}
          </ul>
        </div>
      </div>
    </Wrapper>
  );
};
