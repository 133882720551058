/* eslint-disable max-len */
import React from 'react';

export const Instagram = () => (
  <svg id="Instagram" xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23">
    <path id="Path_16952" data-name="Path 16952" d="M211.5,209.249A2.249,2.249,0,1,1,209.249,207,2.249,2.249,0,0,1,211.5,209.249Zm0,0" transform="translate(-197.749 -197.749)" />
    <path id="Path_16953" data-name="Path 16953" d="M146.276,137.041A2.234,2.234,0,0,0,145,135.76a3.733,3.733,0,0,0-1.253-.232c-.712-.032-.925-.039-2.726-.039s-2.015.007-2.726.039a3.735,3.735,0,0,0-1.253.232,2.235,2.235,0,0,0-1.281,1.281,3.734,3.734,0,0,0-.232,1.253c-.032.711-.039.925-.039,2.726s.007,2.015.039,2.726a3.733,3.733,0,0,0,.232,1.253,2.234,2.234,0,0,0,1.28,1.281,3.729,3.729,0,0,0,1.253.232c.711.032.925.039,2.726.039s2.015-.007,2.726-.039A3.729,3.729,0,0,0,145,146.28,2.234,2.234,0,0,0,146.276,145a3.737,3.737,0,0,0,.232-1.253c.032-.712.039-.925.039-2.726s-.007-2.015-.039-2.726A3.729,3.729,0,0,0,146.276,137.041Zm-5.26,7.444a3.465,3.465,0,1,1,3.465-3.465A3.465,3.465,0,0,1,141.016,144.485Zm3.6-6.257a.81.81,0,1,1,.81-.81A.81.81,0,0,1,144.618,138.228Zm0,0" transform="translate(-129.516 -129.52)" />
    <path id="Path_16954" data-name="Path 16954" d="M11.5,0A11.5,11.5,0,1,0,23,11.5,11.5,11.5,0,0,0,11.5,0Zm6.564,14.222a4.845,4.845,0,0,1-.307,1.6,3.377,3.377,0,0,1-1.931,1.931,4.848,4.848,0,0,1-1.6.307c-.7.032-.929.04-2.723.04s-2.018-.008-2.723-.04a4.848,4.848,0,0,1-1.6-.307,3.376,3.376,0,0,1-1.931-1.931,4.844,4.844,0,0,1-.307-1.6c-.032-.7-.04-.929-.04-2.723s.008-2.018.04-2.723a4.845,4.845,0,0,1,.307-1.6A3.379,3.379,0,0,1,7.174,5.243a4.849,4.849,0,0,1,1.6-.307c.7-.032.929-.04,2.723-.04s2.018.008,2.723.04a4.85,4.85,0,0,1,1.6.307,3.377,3.377,0,0,1,1.931,1.931,4.844,4.844,0,0,1,.307,1.6c.032.7.04.929.04,2.723S18.1,13.518,18.064,14.222Zm0,0" />
  </svg>
);
