import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { API, Storage } from 'aws-amplify';

import { TRUCK_STATUS } from '../FreightDrivers/constants';
import { BOX_TYPE, CONDITIONS, FUEL_TYPE } from './constants';
import {
  GetTruck,
  UpdateTruck,
  UpdateBox,
} from '../FreightDriverTruck/graphql';
import { Wrapper, Status, UploadPhoto } from '../FreightDriverTruck/styles';
import { Icon } from '../../Icon';
import { Input } from './input';

interface IFreightDriver {
  id: string;
}

interface IBox {
  id: string;
  type: string;
  width: number;
  height: number;
  length: number;
}

interface IAduanaDocumentation {
  type: string;
  document: string;
}

interface ITruck {
  id: string;
  status: string;
  brand?: string;
  model?: string;
  yearModel?: string;
  fuelType?: string;
  enrolNumber?: string;
  hasGPS?: boolean;
  fuelEfficiency?: number;
  tons?: number;
  axles?: number;
  surplusAxles?: number;
  frontPhoto?: string | File;
  sidePhoto?: string | File;
  backPhoto?: string | File;
  measuresPhoto?: string | File;
  circulationCardPhoto?: string | File;
  vehicleVerificationPhoto?: string | File;
  insurancePolicyPhoto?: string | File;
  utilityPercentage?: number;
  aduanaDocumentation: IAduanaDocumentation[];
  box: IBox;
  freightDriver: IFreightDriver;
}

interface IImages {
  frontPhotos?: string;
  sidePhotos?: string;
  backPhotos?: string;
  measuresPhotos?: string;
  circulationCardPhotos?: string;
  vehicleVerificationPhotos?: string;
  insurancePolicyPhotos?: string;
  aduanaDocumentation?: IAduanaDocumentation[];
  companyPicture?: string;
  identificationDocumentPhoto?: string;
}

export const FreightDriverTruckEdit = () => {
  const router = useHistory();
  const { truckId } = useParams<{
    truckId: string;
  }>();
  const [status, setStatus] = useState('idle');
  const [mutationStatus, setMutationStatus] = useState('idle');
  const [truck, setTruck] = useState<ITruck>({
    id: '',
    status: '',
    brand: '',
    model: '',
    yearModel: '',
    fuelType: '',
    enrolNumber: '',
    hasGPS: false,
    fuelEfficiency: 0,
    tons: 0,
    axles: 0,
    surplusAxles: 0,
    frontPhoto: '',
    sidePhoto: '',
    backPhoto: '',
    measuresPhoto: '',
    circulationCardPhoto: '',
    vehicleVerificationPhoto: '',
    insurancePolicyPhoto: '',
    utilityPercentage: 0,
    aduanaDocumentation: [],
    box: {
      id: '',
      type: '',
      width: 0,
      height: 0,
      length: 0,
    },
    freightDriver: {
      id: '',
    },
  });
  const [images, setImages] = useState<IImages>({
    frontPhotos: '',
    sidePhotos: '',
    backPhotos: '',
    measuresPhotos: '',
    circulationCardPhotos: '',
    vehicleVerificationPhotos: '',
    insurancePolicyPhotos: '',
    aduanaDocumentation: [],
  });
  const isLoading = status === 'idle' || status === 'pending';
  const isMutationLoading = mutationStatus === 'pending';

  useEffect(() => {
    async function getQuery() {
      setStatus('pending');

      try {
        const { data } = (await API.graphql({
          query: GetTruck,
          variables: {
            id: truckId,
          },
        })) as any;

        if (data) {
          setTruck(data.getTruck);
        }

        setStatus('resolved');
      } catch {
        setStatus('rejected');
      }
    }

    getQuery();
  }, [truckId]);

  useEffect(() => {
    const getImages = async () => {
      if (status === 'resolved') {
        const docImages = (await Promise.all([
          truck.frontPhoto
            ? (Storage.get(truck.frontPhoto as string) as Promise<string>)
            : (Promise.resolve('') as Promise<string>),
          truck.sidePhoto
            ? (Storage.get(truck.sidePhoto as string) as Promise<string>)
            : (Promise.resolve('') as Promise<string>),
          truck.backPhoto
            ? (Storage.get(truck.backPhoto as string) as Promise<string>)
            : (Promise.resolve('') as Promise<string>),
          truck.measuresPhoto
            ? (Storage.get(truck.measuresPhoto as string) as Promise<string>)
            : (Promise.resolve('') as Promise<string>),
          truck.circulationCardPhoto
            ? (Storage.get(
                truck.circulationCardPhoto as string
              ) as Promise<string>)
            : (Promise.resolve('') as Promise<string>),
          truck.vehicleVerificationPhoto
            ? (Storage.get(
                truck.vehicleVerificationPhoto as string
              ) as Promise<string>)
            : (Promise.resolve('') as Promise<string>),
          truck.insurancePolicyPhoto
            ? (Storage.get(
                truck.insurancePolicyPhoto as string
              ) as Promise<string>)
            : (Promise.resolve('') as Promise<string>),
        ])) as string[];

        setImages(prevValues => ({
          ...prevValues,
          frontPhotos: docImages[0],
          sidePhotos: docImages[1],
          backPhotos: docImages[2],
          measuresPhotos: docImages[3],
          circulationCardPhotos: docImages[4],
          vehicleVerificationPhotos: docImages[5],
          insurancePolicyPhotos: docImages[6],
          companyPicture: docImages[7],
          identificationDocumentPhoto: docImages[8],
        }));

        if (truck.aduanaDocumentation.length > 0) {
          const aduanaDocumentation = await Promise.all(
            truck.aduanaDocumentation.map(async item => ({
              type: item.type,
              document: (await Storage.get(item.document)) as string,
            }))
          );

          setImages(prevValues => ({ ...prevValues, aduanaDocumentation }));
        }
      }
    };

    getImages();
  }, [status]);

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    event.preventDefault();

    const { name, value } = event.target;

    if (!name || !value) return;

    setTruck(prevValues => {
      if (name === 'hasGPS') {
        return {
          ...prevValues,
          [name]: value === 'Sí',
        };
      }

      if (name.includes('.')) {
        const [head, key] = name.split('.') as ['box', string];

        return {
          ...prevValues,
          [head]: {
            ...prevValues[head],
            [key]: value,
          },
        };
      }

      return {
        ...prevValues,
        [name]: value,
      };
    });
  };

  const handleFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();

    const { name, files } = event.target;

    if (!name || !files || files?.length === 0) return;

    const file = files[0];

    if (!file.type.includes('image') && !file.type.includes('pdf')) return;

    setTruck(prevValues => ({ ...prevValues, [name]: file }));
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const id = truck?.id;

    if (!id) return;

    setMutationStatus('pending');

    const truckFrontPhoto = (truck.frontPhoto as File)?.name
      ? `freight-driver/${truck.freightDriver.id}/trucks/front_photos/${
          (truck.frontPhoto as File)?.name
        }`
      : truck.frontPhoto;
    const truckSidePhoto = (truck.sidePhoto as File)?.name
      ? `freight-driver/${truck.freightDriver.id}/trucks/side_photos/${
          (truck.sidePhoto as File)?.name
        }`
      : truck.sidePhoto;
    const truckBackPhoto = (truck.backPhoto as File)?.name
      ? `freight-driver/${truck.freightDriver.id}/trucks/back_photos/${
          (truck.backPhoto as File)?.name
        }`
      : truck.backPhoto;
    const truckMeasuresPhoto = (truck.measuresPhoto as File)?.name
      ? `freight-driver/${truck.freightDriver.id}/trucks/measures_photos/${
          (truck.measuresPhoto as File)?.name
        }`
      : truck.measuresPhoto;
    const truckCirculationCardPhoto = (truck.circulationCardPhoto as File)?.name
      ? `freight-driver/${
          truck.freightDriver.id
        }/trucks/circulation_card_photos/${
          (truck.circulationCardPhoto as File)?.name
        }`
      : truck.circulationCardPhoto;
    const truckVehicleVerificationPhoto = (truck.vehicleVerificationPhoto as File)
      ?.name
      ? `freight-driver/${
          truck.freightDriver.id
        }/trucks/vehicle_verification_photos/${
          (truck.vehicleVerificationPhoto as File)?.name
        }`
      : truck.vehicleVerificationPhoto;
    const truckInsurancePolicyPhoto = (truck.insurancePolicyPhoto as File)?.name
      ? `freight-driver/${
          truck.freightDriver.id
        }/trucks/insurance_policy_photos/${
          (truck.insurancePolicyPhoto as File)?.name
        }`
      : truck.insurancePolicyPhoto;

    const updateBoxResponse = (await API.graphql({
      query: UpdateBox,
      variables: {
        input: {
          id: truck.box.id,
          type: truck.box.type,
          width: truck.box.width,
          height: truck.box.height,
          length: truck.box.length,
        },
      },
    })) as any;

    if (updateBoxResponse && updateBoxResponse.data.updateBox.id) {
      await Promise.all([
        API.graphql({
          query: UpdateTruck,
          variables: {
            input: {
              id: truck.id,
              brand: truck.brand,
              model: truck.model,
              yearModel: truck.yearModel,
              fuelType: truck.fuelType,
              enrolNumber: truck.enrolNumber,
              hasGPS: truck.hasGPS,
              fuelEfficiency: truck.fuelEfficiency,
              tons: truck.tons,
              axles: truck.axles,
              surplusAxles: truck.surplusAxles,
              frontPhoto: truckFrontPhoto,
              sidePhoto: truckSidePhoto,
              backPhoto: truckBackPhoto,
              measuresPhoto: truckMeasuresPhoto,
              circulationCardPhoto: truckCirculationCardPhoto,
              vehicleVerificationPhoto: truckVehicleVerificationPhoto,
              insurancePolicyPhoto: truckInsurancePolicyPhoto,
              utilityPercentage: truck.utilityPercentage,
            },
          },
        }) as any,
        (truck.frontPhoto as File)?.name
          ? Storage.put(truckFrontPhoto as string, truck.frontPhoto)
          : Promise.all(''),
        (truck.sidePhoto as File)?.name
          ? Storage.put(truckSidePhoto as string, truck.sidePhoto)
          : Promise.resolve(''),
        (truck.backPhoto as File)?.name
          ? Storage.put(truckBackPhoto as string, truck.backPhoto)
          : Promise.resolve(''),
        (truck.measuresPhoto as File)?.name
          ? Storage.put(truckMeasuresPhoto as string, truck.measuresPhoto)
          : Promise.resolve(''),
        (truck.circulationCardPhoto as File)?.name
          ? Storage.put(
              truckCirculationCardPhoto as string,
              truck.circulationCardPhoto
            )
          : Promise.resolve(''),
        (truck.vehicleVerificationPhoto as File)?.name
          ? Storage.put(
              truckVehicleVerificationPhoto as string,
              truck.vehicleVerificationPhoto
            )
          : Promise.resolve(''),
        (truck.insurancePolicyPhoto as File)?.name
          ? Storage.put(
              truckInsurancePolicyPhoto as string,
              truck.insurancePolicyPhoto
            )
          : Promise.resolve(''),
      ])
        .then(() => {
          router.push(`/trucks/${truck.id}`);
        })
        .catch(error => {
          console.error(error);

          alert('Error!! Mira la consola para más información');
        });
    }
  };

  if (isLoading) {
    return <div />;
  }

  return (
    <Wrapper>
      <div className="section">
        <div className="first-card">
          <figure className="icon">
            <Icon iconType="localshipping" />
          </figure>
          <p className="text">
            {truck.brand}, {truck.yearModel} <span>&nbsp;·&nbsp;</span> Soporta{' '}
            {truck.tons} toneladas
          </p>
          {truck?.status && (
            <Status
              color={
                TRUCK_STATUS.find(option => option.status === truck.status)
                  ?.color
              }
            >
              <Icon
                iconType={
                  TRUCK_STATUS.find(option => option.status === truck.status)
                    ?.icon
                }
              />
              <span>
                {
                  TRUCK_STATUS.find(option => option.status === truck.status)
                    ?.text
                }
              </span>
            </Status>
          )}
        </div>
      </div>
      <form
        method="POST"
        onSubmit={isMutationLoading ? undefined : handleSubmit}
      >
        <div className="section">
          <h3 className="section-name">Fotografías</h3>
          <div className="pictures truck">
            <UploadPhoto
              image={
                truck.frontPhoto instanceof File
                  ? URL.createObjectURL(truck.frontPhoto)
                  : (images.frontPhotos as string)
              }
            >
              <input
                type="file"
                id="frontPhoto"
                accept="image/*"
                name="frontPhoto"
                onChange={handleFile}
              />
              <label className="picture" htmlFor="frontPhoto">
                <Icon iconType="add" />
              </label>
            </UploadPhoto>
            <UploadPhoto
              image={
                truck.sidePhoto instanceof File
                  ? URL.createObjectURL(truck.sidePhoto)
                  : (images.sidePhotos as string)
              }
            >
              <input
                type="file"
                id="sidePhoto"
                accept="image/*"
                name="sidePhoto"
                onChange={handleFile}
              />
              <label className="picture" htmlFor="sidePhoto">
                <Icon iconType="add" />
              </label>
            </UploadPhoto>
            <UploadPhoto
              image={
                truck.backPhoto instanceof File
                  ? URL.createObjectURL(truck.backPhoto)
                  : (images.backPhotos as string)
              }
            >
              <input
                type="file"
                id="backPhoto"
                accept="image/*"
                name="backPhoto"
                onChange={handleFile}
              />
              <label className="picture" htmlFor="backPhoto">
                <Icon iconType="add" />
              </label>
            </UploadPhoto>
            <UploadPhoto
              image={
                truck.measuresPhoto instanceof File
                  ? URL.createObjectURL(truck.measuresPhoto)
                  : (images.measuresPhotos as string)
              }
            >
              <input
                type="file"
                id="measuresPhoto"
                accept="image/*"
                name="measuresPhoto"
                onChange={handleFile}
              />
              <label className="picture" htmlFor="measuresPhoto">
                <Icon iconType="add" />
              </label>
            </UploadPhoto>
          </div>
        </div>
        <div className="section">
          <h3 className="section-name">Datos generales</h3>
          <div className="box">
            <ul className="box-items">
              <li className="item">
                <h4 className="item-name">Marca del vehículo</h4>
                <Input
                  name="brand"
                  value={truck.brand}
                  onChange={handleChange}
                />
              </li>
              <li className="item">
                <p className="item-name">Modelo del vehículo</p>
                <Input
                  name="model"
                  value={truck.model}
                  onChange={handleChange}
                />
              </li>
              <li className="item">
                <p className="item-name">Año del vehículo</p>
                <Input
                  name="yearModel"
                  value={truck.yearModel}
                  onChange={handleChange}
                />
              </li>
              <li className="item">
                <p className="item-name">Número de matrícula</p>
                <Input
                  name="enrolNumber"
                  value={truck.enrolNumber}
                  onChange={handleChange}
                />
              </li>
              <li className="item">
                <p className="item-name">GPS</p>
                <select
                  className="item-value"
                  name="hasGPS"
                  value={truck.hasGPS ? 'Sí' : 'No'}
                  onChange={handleChange}
                >
                  {CONDITIONS.map((condition, index) => (
                    <option value={condition} key={index}>
                      {condition}
                    </option>
                  ))}
                </select>
              </li>
            </ul>
          </div>
        </div>
        <div className="section">
          <h3 className="section-name">Carga</h3>
          <div className="box">
            <ul className="box-items">
              <li className="item">
                <p className="item-name">Tipo de combustible</p>
                <select
                  className="item-value"
                  name="fuelType"
                  value={truck.fuelType}
                  onChange={handleChange}
                >
                  {FUEL_TYPE.map((fuel, index) => (
                    <option value={fuel} key={index}>
                      {fuel}
                    </option>
                  ))}
                </select>
              </li>
              <li className="item">
                <p className="item-name">Rendimiento gasolina KM/Litro</p>
                <Input
                  name="fuelEfficiency"
                  value={truck.fuelEfficiency}
                  onChange={handleChange}
                />
              </li>
              <li className="item">
                <p className="item-name">Toneladas que soporta</p>
                <Input name="tons" value={truck.tons} onChange={handleChange} />
              </li>
              <li className="item">
                <p className="item-name">Ejes del camión</p>
                <Input
                  name="axles"
                  value={truck.axles}
                  onChange={handleChange}
                />
              </li>
              <li className="item">
                <p className="item-name">Ejes excedentes del camión</p>
                <Input
                  name="surplusAxles"
                  value={truck.surplusAxles}
                  onChange={handleChange}
                />
              </li>
            </ul>
          </div>
        </div>
        <div className="section">
          <h3 className="section-name">Caja</h3>
          <div className="box">
            <ul className="box-items">
              <li className="item">
                <p className="item-name">Tipo de caja de camión</p>
                <select
                  className="item-value"
                  name="box.type"
                  value={truck.box.type}
                  onChange={handleChange}
                >
                  {BOX_TYPE.map((type, index) => (
                    <option value={type} key={index}>
                      {type}
                    </option>
                  ))}
                </select>
              </li>
              <li className="item">
                <p className="item-name">Ancho de la caja en metros</p>
                <Input
                  name="box.width"
                  value={truck.box.width}
                  onChange={handleChange}
                />
              </li>
              <li className="item">
                <p className="item-name">Altura de la caja en metros</p>
                <Input
                  name="box.height"
                  value={truck.box.height}
                  onChange={handleChange}
                />
              </li>
              <li className="item">
                <p className="item-name">Longitud de caja</p>
                <Input
                  name="box.length"
                  value={truck.box.length}
                  onChange={handleChange}
                />
              </li>
            </ul>
          </div>
        </div>
        <div className="section">
          <h3 className="section-name">Ganancias</h3>
          <div className="box">
            <ul className="box-items">
              <li className="item">
                <p className="item-name">Porcentaje de utilidad</p>
                <Input
                  name="utilityPercentage"
                  value={truck.utilityPercentage}
                  onChange={handleChange}
                />
              </li>
            </ul>
          </div>
        </div>
        <div className="section">
          <h3 className="section-name">Documentación</h3>
          <div className="pictures documentation">
            <UploadPhoto
              image={
                truck.circulationCardPhoto instanceof File
                  ? URL.createObjectURL(truck.circulationCardPhoto)
                  : (images.circulationCardPhotos as string)
              }
            >
              <input
                type="file"
                id="circulationCardPhoto"
                accept="image/*"
                name="circulationCardPhoto"
                onChange={handleFile}
              />
              <label className="picture" htmlFor="circulationCardPhoto">
                <Icon iconType="add" />
              </label>
            </UploadPhoto>
            <UploadPhoto
              image={
                truck.vehicleVerificationPhoto instanceof File
                  ? URL.createObjectURL(truck.vehicleVerificationPhoto)
                  : (images.vehicleVerificationPhotos as string)
              }
            >
              <input
                type="file"
                id="vehicleVerificationPhoto"
                accept="image/*"
                name="vehicleVerificationPhoto"
                onChange={handleFile}
              />
              <label className="picture" htmlFor="vehicleVerificationPhoto">
                <Icon iconType="add" />
              </label>
            </UploadPhoto>
            <UploadPhoto
              image={
                truck.insurancePolicyPhoto instanceof File
                  ? URL.createObjectURL(truck.insurancePolicyPhoto)
                  : (images.insurancePolicyPhotos as string)
              }
            >
              <input
                type="file"
                id="insurancePolicyPhoto"
                accept="image/*"
                name="insurancePolicyPhoto"
                onChange={handleFile}
              />
              <label className="picture" htmlFor="insurancePolicyPhoto">
                <Icon iconType="add" />
              </label>
            </UploadPhoto>
          </div>
        </div>
        {images?.aduanaDocumentation &&
        images?.aduanaDocumentation.length > 0 ? (
          <>
            <div className="section">
              <h3 className="section-name">Permiso de aduanas</h3>
              <div className="box">
                <ul className="box-items">
                  {images.aduanaDocumentation.map((aduana, index) => (
                    <li className="item" key={index}>
                      <p className="item-name">Aduana</p>
                      <p className="item-value">{aduana.type}</p>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="section">
              <h3 className="section-name">Documentación aduanas</h3>
              <div className="box">
                <ul className="box-items">
                  {images.aduanaDocumentation.map((aduana, index) => (
                    <a href={aduana.document} className="item" key={index}>
                      <p className="item-name">{aduana.type}</p>
                      <p className="item-value">Documento</p>
                    </a>
                  ))}
                </ul>
              </div>
            </div>
          </>
        ) : null}
        <button
          type="submit"
          className="save-button"
          disabled={isMutationLoading}
        >
          SAVE IT NOW!
        </button>
      </form>
    </Wrapper>
  );
};
